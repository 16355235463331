import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import sqTranslation from "./assets/languages/al.json";
import enTranslation from "./assets/languages/en.json";
import {parseLanguageLocaleString} from "./utils/utils";

const lng = parseLanguageLocaleString(localStorage.getItem("language"));

if (!localStorage.getItem("language")) {
  localStorage.setItem("language", lng);
}

i18n.use(initReactI18next).init({
  resources: {
    al: { translation: sqTranslation },
    en: { translation: enTranslation },
  },
  lng: lng,
  fallbackLng: lng,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
