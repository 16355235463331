import React, { useMemo } from "react";
import styles from "./Presidenti.module.scss";
import President from "./../../assets/images/presidenti.jpeg";
import { useLocation } from "react-router-dom";

const DATA = {
  presidenti: {
    firstImage: President,
    secondImage: President,
    title: "Presidenti i FFK-së Agim Ademi",
    description: `
      <h4>Agim Ademi u lind më 19.10.1961. Është i martuar dhe jeton në Prishtinë. Ka magjistruar në shkencat e sigurisë dhe emergjencave, derisa njihet si biznismen i suksesshëm në fushën e ndërtimtarisë, hotelierisë dhe prodhimit.</h4>
      <h4>Nga viti 1975 deri në vitin 1988 ishte futbollist aktiv. Nga viti 1992 Agim Ademi kontributin e tij e dha në zhvillimin e futbollit kosovar, si kryetar dhe donator i KF “Kosova” (P), klub i cili në një periudhë pati paraqitje të suksesshme në elitën e futbollit kosovar.</h4>
      <h4>Nga viti 2000 e deri më 6 korrik, 2018 kontributin e tij e dha si zëvendëspresident i FFK-së, kurse nga kjo ditë iu besua posti i presidentit të FFK-së, ku u votua unanimisht nga 58 delegatët në Kuvendin e Jashtëzakonshëm Zgjedhor, me mandat katërvjeçar 2018-2022, pasi pozita e presidentit kishte mbetur vakante pas vdekjes së legjendës së futbollit tonë, Fadil Vokrri. Ndërkaq, në prill të vitit 2022, ai, u rizgjodh në këtë pozitë me shumicë të votave të delegatëve edhe për mandatin e ri katërvjeçar 2022 – 2026.</h4>
    `,
  },
  sekretari: {
    firstImage: President,
    secondImage: President,
    title: "Sekretar i përgjithshëm I FFK-SË Markus Weidner",
    description: `
      <h4>Markus Weidner u lind më 28/11/1968 në Frankfurt. Është ekspert në menaxhimin e futbollit dhe marrëdhëniet ndërkombëtare. Ka përvojë 25 vjeçare në futbollin profesionistë në nivel të klubeve dhe federatave kombëtare.</h4>
      <h4>Ka qenë drejtor i Marrëdhënieve Ndërkombëtare në Eintracht Frankfurt Fußball AG, pastaj shef i edukimit për trajnerë dhe Marrëdhënieve Ndërkombëtare në Federatën e Futbollit të Gjermanisë (DFB) si dhe zëvendësdrejtor në DFB. Krahas gjuhës gjermane flet edhe anglisht dhe frëngjisht.</h4>
    `,
  },
};

const Presidenti = () => {
  const location = useLocation();

  const dataToRender = useMemo(() => {
    if (location.pathname === "/presidenti") {
      return DATA.presidenti;
    }
    return DATA.sekretari;
  }, [location.pathname]);

  return (
    <div className={styles.MainWrapper}>
      <div className={styles.PrimaryTitleWrapper}>
        <img
          src={dataToRender.firstImage}
          alt={dataToRender.title}
          className={styles.FirstImage}
        />
        <div className={styles.Content}>
          <h1>{dataToRender.title}</h1>
          <div
            className={styles.Description}
            dangerouslySetInnerHTML={{ __html: dataToRender.description }}
          />
        </div>
        {/*<img*/}
        {/*  src={dataToRender.secondImage}*/}
        {/*  alt={dataToRender.title}*/}
        {/*  className={styles.SecondImage}*/}
        {/*/>*/}
      </div>
    </div>
  );
};

export default Presidenti;
