import React from "react";

const DataNotFound = ({ loading }: { loading?: boolean }) => {
  return (
    <div style={{ display: "flex", justifyContent: "center", marginTop: '3rem' }}>
      <h1>{loading ? "loading..." : "Data not found"}</h1>
    </div>
  );
};

export default DataNotFound;
