import React, {useRef} from "react"
import styles from "./FanClub.module.scss";
import Dardanet from "../../assets/images/Dardanet.png"

const FanClub = () => {

  return (
      <div className={styles.MainWrapper}>
        <div className={styles.ListWrapper}>
          <div className={styles.DardanetContainer}>
            <h1>Dardanët</h1>
            <a href="https://www.facebook.com/dardanetks/" target="_blank" rel="noopener noreferrer">
              <img src={Dardanet} alt="Kosovo" style={{cursor: "pointer", width: "100%"}}/>
            </a>
            <p>
              Themelimi i tifo grupit kombëtar “Dardanët” ka filluar në vitin 2003 me idenë e mbledhjes së të gjitha
              tifozërive të Kosovës
              në përkrahje të Kombëtares së Shqipërisë, pasi atëbotë Kosova ende ishte nën administrim
              ndërkombëtar dhe si e tillë nuk ishte pjesë e UEFA-s dhe FIFA-s. Dardanët ishin prezent në çdo ndeshje
              të Kombëtares së Shqipërisë, të organizuar masovikisht ndërmerren rrugë
              me shtigje e infrastrukturë minimale vetëm e vetëm që të ofrohet përkrahje dhe brohoritje për Kuqezinjtë.
            </p>
            <br></br>
            <p>
              Ky rrugëtim i yni në përkrahje të Kuqezinjve ka vazhduar pandërprerë deri në vitin 2016,
              deri në atë kohë kur vendi ynë, pas shumë përpjekjeve dhe sakrificave u njoh dhe u pranua zyrtarisht në
              UEFA dhe FIFA.
              
              Me këtë fillon edhe epoka e Dardanëve në përkrahje të Kosovës, ku më 13 shtator 2016,
              zyrtarët e FFK-së i bënë ftesë të gjitha tifozërive të Kosovës për të krijuar një organizim për të
              zgjedhur një trupë
              që do ta drejtonte tifo grupin kombëtar ‘Dardanët’. Nën organizimin dhe përkrahjen e FFK-së,
              pothuajse të gjitha tifo grupet kosovare mblidhen që të ndihmojnë në zgjedhjen e drejtuesit të tifo grupit
              kombëtar ‘Dardanët’,
              dhe pas konsultimeve dhe diskutimeve të shumta zgjidhet kryesia e cila është trupa e vetme e njohur dhe që
              përfshin nën ombrellën e sajtë gjitha tifozeritë e vendit. Kjo ka bërë që,
              Dardanët të shtrijnë zgjerimin në të gjitha viset e Kosovës dhe jo vetëm.
              Fillimi i organizimit ishte mjaft sfidues dhe me sakrifica. Kryesia e re luajti një rol vendimtar në
              grumbullimin e tifozëve
              dhe ngritjen e emocioneve rreth ndeshjeve të para të kombëtares sonë në garat ndërkombëtare.
              Dardanët kanë organizuar shumë tubime tifozësh dhe fushata në mediat sociale për të rritur mbështetjen për
              ekipin kombëtar.
              Në këtë rrugëtim u përfshinë shumë tifozë, futbollistë, sportdashës, artistë, gazetarë e afaristë,
              të cilët ndihmuan në krijimin e një identiteti që themelet e saja i ka thellë e të vendosura në
              identitetin tonë historik Dardan.
            </p>
            <br></br>
            <p>
              Që nga ajo kohë, Dardanët kanë dëshmuar organizim të mirëfilltë qoftë në fitore apo edhe në momente
              jo aq të mira në rrugëtimin e futbollit të Kosovës. Nga fitoret e paharrueshme deri te kualifikimi historik
              në Ligën e Kombeve, grupimi ynë ka qenë aty në çdo hap, duke brohoritur me pasion dhe entuziazëm të pashoq.
              Dardanët janë të njohur për atmosferën e zjarrtë që krijojnë në çdo ndeshje, qoftë brenda vendit apo jashtë.
              Sloganet e tyre kryesore janë “Unë biri Yt, Kosovë” dhe “Besa-Besë”, derisa në çdo ndeshje përkujdesen
              për koreografi, rekuizita dhe ide të shkëlqyeshme në mbështetje të futbollistëve dardanë në fushë.
              Kjo është një pjesë e rrugëtimit të tifo grupit kombëtar ‘Dardanët’, që doli nga një dëshirë kolektive
              për të bashkuar tifozët dhe për të ofruar mbështetje të palëkundur për ekipin kombëtar të Kosovës.
            </p>
          </div>
        </div>
      </div>
  )
}

export default FanClub;