import React, {useState} from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./containers/home/Home";
import styles from "./App.module.scss";
import Competition from "./containers/competitions/Competition";
import PlaymakersGrassroots from "./containers/playmakers-glassroots/PlaymakersGrassroots";
import FanClub from "./containers/fan-club/FanClub";
import Administration from "./containers/administration/Administration";
import Volunteers from "./containers/volunteers/Volunteers";
import ScrollToTop from "./containers/scroll-to-top/ScrollToTop";
import Intermediaries from "./containers/intermediaries/Intermediaries";
import Committees from "./containers/committees/Committees";
import Committee from "./containers/committee/Committee";
import Presidenti from "./containers/presidenti/Presidenti";
import NormativeActs from "./containers/normative-acts/NormativeActs";
import News from "./containers/news/News";
import NewsDetails from "./containers/news-details/NewsDetails";
import NotFound from "./containers/not-found/NotFound";
import SearchResults from "./components/search-result/SearchResults";
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";
import FFKShop from "./containers/ffk-shop/FFK-shop";
import FFKHistory from "./containers/ffk-history/FFKHistory";
import CookieConsent from "./components/_shared/cookies/CookieConsent";
import TermsOfUse from "./containers/terms-and-conditions/TermsOfUse";
import PrivacyPolicy from "./containers/terms-and-conditions/PrivacyPolicy";
import Contact from "./containers/contact-page/Contact";

const App = () => {
  return (
      <div className={styles.RoutesWrapper}>
        <ScrollToTop />
        <CookieConsent />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/intermediary" element={<Intermediaries />} />
          <Route path="/committees" element={<Committees />} />
          <Route path="/committee" element={<Committee />} />
          <Route path="/presidenti" element={<Presidenti />} />
          <Route path="/sekretar-i-pergjithshem" element={<Presidenti />} />
          <Route path="/normative-acts" element={<NormativeActs />} />
          <Route path="/garat/:title" element={<Competition />} />
          <Route path="/lajmet" element={<News />} />
          <Route path="/lajmet/:title" element={<NewsDetails />} />
          <Route path="/administration" element={<Administration />} />
          <Route path="/ffk-historiku" element={<FFKHistory />} />
          <Route
              path="/volunteers"
              element={
                <GoogleReCaptchaProvider reCaptchaKey="6Lcx8c0qAAAAAOYfMV-y85yrIrbva3jP3YUrN3OD">
                  <Volunteers />
                </GoogleReCaptchaProvider>
              }
          />
          <Route path="/fan-club" element={<FanClub />} />
          <Route path="/ffk-shop" element={<FFKShop />} />
          <Route path="/playmakers" element={<PlaymakersGrassroots />} />
          <Route path="/grassroots" element={<PlaymakersGrassroots />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/search" element={<SearchResults />} />
          <Route path="/termsOfUse" element={<TermsOfUse />} />
          <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </div>
  );
};

export default App;
