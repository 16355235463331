import React, {useRef} from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {formatDate, splitTextByDelimiter2} from "../../utils/utils";
import { News } from "../../types/types";
import { Carousel } from "antd";
import styles from "./NewsDetails.module.scss";
import { CarouselRef } from "antd/es/carousel";
import DataNotFound from "../../components/_shared/data-not-found/DataNotFound";
import DOMPurify from "dompurify";

const NewsDetails = () => {
  const { i18n } = useTranslation();
  const location = useLocation();
  const state = location.state as News;
  const carouselRef = useRef<CarouselRef>(null);

  const description =
      i18n.language === "al" ? state.alDescription : state.enDescription;
  const descriptionSections = splitTextByDelimiter2(description);

  return (
      <div className={styles.MainWrapper}>
        {state ? (
            <div className={styles.ContentWrapper}>
              <h1>{i18n.language === "al" ? state.alTitle : state.enTitle}</h1>
              <img src={state.mainImageUrl} alt="news main image" />

              <div className={styles.DescriptionWrapper}>
                <p>{state.publishedDate && formatDate(state.publishedDate)}</p>

                <div
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(descriptionSections, {
                        ALLOWED_TAGS: ['b', 'i', 'em', 'u', 'a', 'p', 'br', 'ul', 'ol', 'li']
                      })
                    }}
                />
              </div>

              {state.pdfUrl && (
                  <div className={styles.IframeWrapper}>
                    <iframe src={state.pdfUrl} title="pdf"></iframe>
                  </div>
              )}

              {state.additionalImageUrls &&
                  state.additionalImageUrls.length > 0 && (
                      <div className={styles.AdditionalImagesWrapper}>
                        <Carousel
                            ref={carouselRef}
                            autoplay
                            dots={false}
                            infinite
                            className={styles.AdditionalImages}
                        >
                          {state.additionalImageUrls.map((imageUrl, index) => (
                              <img
                                  src={imageUrl}
                                  alt={`additional image ${index + 1}`}
                                  key={index}
                              />
                          ))}
                        </Carousel>
                        <div className={styles.ArrowWrapper}>
                          <button
                              className={styles.Arrow}
                              onClick={() => {
                                if (carouselRef.current) {
                                  carouselRef.current.prev();
                                }
                              }}
                          >
                            ‹
                          </button>
                          <button
                              className={styles.Arrow}
                              onClick={() => {
                                if (carouselRef.current) {
                                  carouselRef.current.next();
                                }
                              }}
                          >
                            ›
                          </button>
                        </div>
                      </div>
                  )}
            </div>
        ) : (
            <DataNotFound />
        )}
      </div>
  );
};

export default NewsDetails;
