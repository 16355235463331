import React, {useMemo} from "react";
import styles from "./FFKHistory.module.scss";
import History from "../../assets/images/kos-his.jpg"
import Kryesite from "./Kryesite";
import Kampionet from "./Kampionet";
import Kampionet1945 from "./Kampionet1945";

const FFKHistory = () => {

  return (
      <div className={styles.MainWrapper}>
        <div className={styles.PrimaryTitleWrapper}>
          <div className={styles.FFkHistoryContainer}>
            <div className={styles.ListWrapper}>
              <h1>Historiku i FFK</h1>
              <img src={History}/>
              <p>Federata e Futbollit e Kosovës u themelua si institucion i pavarur i Republikës së Kosovës, më 20 gusht
                të
                vitit 1991,
                në një periudhë shumë të vështirë, kur vendi ishte okupuar nga regjimi i egër serbo-sllav.
                Futbolli në Kosovë para kësaj date ishte zhvilluar nën organizimin e Lidhjes së Futbollit të Kosovës,
                që ishte pjesë e barabartë me Lidhjet e tjera të Futbollit në Federatën e Jugosllavisë dhe që
                funksiononte
                që nga viti 1948.
                Por, periudha e shpërbërjes së Jugosllavisë me nisjen e luftës në fillim vitet e 90-ta dhe instalimi i
                masave të dhunshme nga Serbia
                në çdo lëmi të jetës në Kosovë, e goditi edhe futbollin, kur nga stadiumet u dëbuan të gjitha ekipet
                shqiptare.
                Të ndodhur në një situatë okupuese e përjashtuese, në gusht të vitit 1991 njerëzit e futbollit në Kosovë
                vendosën
                që përkundër rrethanave të vështira ta ndërmarrin hapin e guximshëm – themelimin e Federatës së
                Futbollit
                të
                Kosovës dhe
                fillimin e garave në tërë vendin, ndonëse në pa mundësi të shfrytëzimit të stadiumeve të futbollit, në
                të
                cilat ishin
                instaluar klubet serbe. Kuvendi konstituiv i FFK-së, të cilit i paraprinë shumë takime sensibilizuese
                nëpër
                qytete të ndryshme,
                u mbajt në shkollën fillore “Asim Vokshi” në lagjen Kodra e Trimave në Prishtinë, derisa drejtimin e
                këtij
                institucioni
                me vota unanime të delegatëve e mori punëtori i dalluar sportiv nga Prizreni, Agim Bytyqi. Ndërkohë,
                vetëm
                disa javë më vonë,
                saktësisht më 13 shtator, filloi edhe kampionati i parë i futbollit të pavarur të Kosovës,
                me ndeshjen ndërmjet dy rivalëve të kryeqytetit, Flamurtarit dhe Prishtinës.
                <br/> <br/>

                Liga e parë e Kosovës në kampionatin e parë numëronte 20 skuadra, ndërsa ligat tjera varësisht nga
                Regjionet,
                por ndeshjet luheshin në kushte shumë të vështira nëpër fusha të improvizuara,
                ku shumë familje i kishin dhuruar tokat e tyre për t’i shndërruar në fusha futbolli,
                vetëm e vetëm që futbolli të luhej dhe të rinjtë ta zhvillonin talentin e tyre.
                Edhe në këto rrethana futbolli luhej sipas rregullave të UEFA-s dhe FIFA-s,
                dhe çdo ndryshim i rregullave të tyre menjëherë aplikohej në sistemin e garave tona.
                <br/> <br/>

                Gjatë kësaj periudhe maltretimet, burgosjet, rrahjet ndaj zyrtarëve,
                trajnerëve dhe futbollistëve nga pushtuesi serb ishin të pranishme po thuajse çdo javë,
                por motivi për të vazhduar tutje ishte i madh, ngase të gjithë besonin se herët a vonë kjo sakrificë
                dhe rezistencë ndaj pushtuesit do të sillte ditë të mira për futbollin në Kosovë.
                Garat në kushte të tilla vazhduan deri në janar të vitit 1998, kur filloi lufta në Kosovë dhe kur shumë
                futbollistë u rreshtuan në UÇK
                në misionin e çlirimit të vendit. Si rrjedhojë u ndal çdo aktivitet deri pas çlirimit të vendit.
                <br/> <br/>

                Menjëherë pas përfundimit të luftës në Kosovë më 1999, Federata e Futbollit e Kosovës u riorganizua me
                të
                gjitha organet e saj,
                derisa klubet u rikthyen në objektet e stadiumeve, të cilat ishin në gjendje të mjerë për shkak të mos
                investimeve
                për thuajse një dekadë nga okupuesit. Futbolli në vitet e para të pasluftës ishte në ekspansion, por më
                pas
                pamundësia
                për t’u anëtarësuar në UEFA e FIFA dhe shpenzimi i madh i donatorëve për t’i mbajtur klubet bëri që
                futbolli
                shpeshherë
                të kalojë në faza të vështira të mbijetesës.
                <br/> <br/>

                Megjithatë, pas shumë peripecive, sakrificave të shumë gjeneratave, në fund Federata e Futbollit e
                Kosovës,
                më 3 maj të vitit 2016 arriti ta finalizojë misionin e nisur më 20 gusht të vitit 1991, duke u
                anëtarësuar
                në UEFA,
                derisa më pas më 13 maj të njëjtit vit u anëtarësua edhe në FIFA. Kjo periudhë vlerësohet edhe si më e
                rëndësishmja për FFK-në,
                pasi nga këtu mori hov zhvillimi i mëtejmë i futbollit të Kosovës në të gjitha aspektet. Kombëtarja e
                Kosovës u bë pjesë
                e kualifikimeve për Evropian dhe Botëror, derisa klubet kosovare filluan garimin në kupat evropiane, që
                ishte ëndrra
                e të gjithë atyre që ishin pjesë dhe kontribuuan për futbollin e Kosovës.</p>

              <Kryesite/>
              <Kampionet/>
            </div>

            <div className={styles.ListWrapper}>
            <h2>Historiku i shkurtër i futbollit në Kosovë 1919-1991</h2>
              <p>
                Topi i parë i futbollit në Kosovë mendohet se është sjellë nga një student i Grenoblit(Francë) më 1919,
                por,
                sipas disa burimeve (“Rilindja”), ndeshja e parë në Kosovë mund të jetë zhvilluar në vitin 1916
                në Prizren ndërmjet ushtarëve austriakë (fjala është për ushtarë austro-hungarezë).

                Në fillim të dekadës së tretë të shekullit të kaluar u formuan disa Shoqata kulturore-sportive
                që ishin aktive herë pas here e më vonë edhe klubet e para futbollistike në Prishtinë, Gjakovë, Prizren,
                Pejë, Mitrovicë, Gjilan, Ferizaj etj. Por, në atë kohë nuk kishte gara të rregullta kampionale.

                Pas përfundimit të Luftës së Dytë Botërore, në Kosovë, sikurse në pjesë të tjera të ish Jugosllavisë
                filluan të organizoheshin gara të rregullta. Këtë në fillim e bënin Këshillat futbollistike të republikave e
                krahinave e më vonë Lidhjet futbollistike të tyre,që edhe e përbënin Lidhjen futbollistike të ish Jugosllavisë.

                Tretmani i kampionateve nuk ishte i barabartë e sidomos ai i Kosovës. Kampioni i Kosovës nuk
                kualifikohej drejtëpërdrejt në Ligën e Dytë të ish Jugosllavisë,
                sepse për të si rang më i lartë i garave ishte caktuar Liga e Serbisë që kohë pas kohe e ndryshonte formatin e garave.
                <br/><br/>

                Në sezonin 1967/68 Lidhja futbollistike e Kosovës, nuk u pajtua me një gjendje të tillë dhe
                mori vendim që kampioni i Ligës së Kosovës mos të vazhdojë në Ligën e Serbisë, bile edhe ekipet
                që ishin në këtë ligë në fund të kampionatit u kthyen në Ligën e Kosovës. Kështu prej këtij sezoni
                skuadra më e mirë e kampionatit të Kosovës merrte pjesë në kualifikimet për Ligën e dytë të
                ish Jugosllavisë e cila kishte dy grupe e ndonjëherë edhe katër grupe.
                <br/><br/>

                Më 1.11.1970 Këshilli ekzekutiv i Lidhjes futbollistike të Jugosllavisë propozoi që prej sezonit 1970/71
                kampioni i Ligës së Kosovës të plasohej drejtpërdrejt në Ligën e Dytë Qendrore (më vonë u quajt edhe
                federative). Propozimin e miratoi në dhjetor të po këtij viti Konferenca (Kuvendi) vjetore e Lidhjes futbollistike të
                Jugosllavisë.
                Këtë e shfrytëzoi KF”Vëllaznimi” i Gjakovës që pas tri dështimesh në kualifikime për Ligën e Dytë, më në
                fund e realizoi, me plot meritë, dëshirën e kahmotshme, duke u bërë skuadra e parë
                që kaloi në rang garash drejtpërdrejt, pa ndeshje kualifikuese.
                <br/><br/>
                Me avancimin e trajtimit të KSA të Kosovës me Ligjin Kushtetues e më vonë edhe Kushtetutën e Kosovës të
                vitit 1974, u përmirësua edhe pozita e Lidhjes futbollistike të Kosovës e
                cila u bë pjesë konstituive e Lidhjes futbollistike të Jugosllavisë.
                <br/><br/>
                Sukseset më të mëdha deri në vitin 1990 i kanë arritur KF “Prishtina” që pesë vjet (1983-1988) ishte
                anëtare e Ligës së parë të ish Jugosllavisë, si dhe KF “Trepça” e Mitrovicës,
                e cila një sezon (1977/78) zhvilloi gara në rangun elitar.
                Prishtina në Ligën e Dytë të ish Jugosllavisë luajti 23 vjet, kurse Trepça 26 vjet (dy kampionate me
                emrin Rudari i Stari Tërgut).
                <br/><br/>
                Kosova në këtë rang garash, në periudhën 1945-1990, prej sezonit 1960/61 dhe 1961/62 përfaqësohej me një
                përkatësisht dy skuadra.
                Më vonë, në çdo sezon luanin më së shpeshti nga tri ekipe kurse në sezonin 1972/73 Kosova përfaqësohej
                me katër ekipe (Prishtina,Trepça, Obiliqi dhe Vëllaznimi i Gjakovës) sikurse edhe në sezonin 1982/83
                kur në Ligën e Dytë të ish Jugosllavisë luajtën Prishtina, Trepça, Vëllaznimi dhe Liria e Prizrenit.
                <br/><br/>
                Në Ligën e Dytë ekipet kosovare luanin zakonisht në grupin lindor me skuadrat e Serbisë,
                Maqedonisë e Malit të Zi e të Vojvodinës.
                <br/><br/>
                Përveç Ligës së parë në Kosovë zhvilloheshin gara edhe në Ligën e Dytë dhe të tjera në rang ndërkomunal
                dhe komunal.
              </p>

              <Kampionet1945/>
            </div>
          </div>
        </div>
      </div>
  );
};

export default FFKHistory;
