import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Pagination } from "antd";
import NewsCard from "../../components/_shared/news-card/NewsCard";
import useFetch from "../../hooks/useFetch";
import { useLocation, useNavigate } from "react-router-dom";
import { PaginatedNewsResponse } from "../../types/types";
import styles from "./News.module.scss";
import { replaceStringSpaces } from "../../utils/utils";
import DataNotFound from "../../components/_shared/data-not-found/DataNotFound";

const News = () => {
  const { t, i18n } = useTranslation();
  const { state } = useLocation();
  const navigate = useNavigate();
  const pageSize = 6;
  const specialCategories = [
    { id: "f81c4fea-504a-4ef3-8871-c9c89a757c3a", name: "KRYESORE" },
    { id: "1834aec1-fa4a-4c19-b133-79e19ff7585e", name: "VENDIMET" },
    { id: "78679015-b818-47d0-b3be-14781552d2fa", name: "TË TJERA" },
  ];
  const isSpecialCategory = specialCategories.some((category) => category.id === state?.subCategory?.id);

  const [selectedCategory, setSelectedCategory] = useState(isSpecialCategory ? state?.subCategory?.id : null);
  const [currentPage, setCurrentPage] = useState(1);

  const { data, loading } = useFetch<PaginatedNewsResponse>(
      `/posts?subCategoryId=${state?.category?.id}&childSubcategoryId=${
          isSpecialCategory ? selectedCategory : state?.subCategory?.id
      }`
  );

  const mainNews = useMemo(() => data?.content[0], [data]);

  const paginatedNews = useMemo(() => {
    if (!data) return [];
    const otherNews = data.content.slice(1);
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return otherNews.slice(startIndex, endIndex);
  }, [data, currentPage]);

  const handlePageChange = useCallback((page: number) => {
    setCurrentPage(page);
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const handleMainNewsNavigation = useCallback(() => {
    if (!mainNews) return;
    const stateObj = { state: { ...mainNews } };

    const url =
        i18n.language === "al"
            ? `/lajmet/${replaceStringSpaces(mainNews.alTitle ?? "")}`
            : `/lajmet/${replaceStringSpaces(mainNews.enTitle ?? "")}`;

    navigate(url, stateObj);
  }, [navigate, mainNews, i18n]);

  return (
      <div className={styles.NewsContainer}>
        <h2>{t("app.news.news", "Informacionet më të reja")}</h2>

        {isSpecialCategory && (
            <div className={styles.ButtonContainer}>
              {specialCategories.map((category) => (
                  <button
                      key={category.id}
                      onClick={() => {
                        setSelectedCategory(category.id);
                        setCurrentPage(1);
                      }}
                      className={`${styles.CategoryButton} ${
                          selectedCategory === category.id ? styles.Active : ""
                      }`}
                  >
                    {category.name}
                  </button>
              ))}
            </div>
        )}

        {data && data.content.length > 0 ? (
            <>
              {mainNews && (
                  <div className={styles.News} onClick={handleMainNewsNavigation}>
                    <img src={mainNews.mainImageUrl} alt="News image" />
                    <div className={styles.MainNews}>
                      <h1>
                        {i18n.language === "al" ? mainNews.alTitle : mainNews.enTitle}
                      </h1>
                    </div>
                  </div>
              )}

              <h1>{t("app.news.main", "Kryesore")}</h1>

              <div className={styles.AllNews}>
                {paginatedNews.map((newsItem, index) => (
                    <NewsCard key={index} {...newsItem} />
                ))}
              </div>

              <Pagination
                  current={currentPage}
                  pageSize={pageSize}
                  total={data?.content.length - 1}
                  onChange={handlePageChange}
                  className={styles.Pagination}
              />
            </>
        ) : (
            <DataNotFound loading={loading} />
        )}
      </div>
  );
};

export default News;
