import React from 'react';
import type { CollapseProps } from 'antd';
import { Collapse } from 'antd';
import styles from "./FFKHistory.module.scss";

const kryestiaEPare = `
Agim Bytyqi, kryetar
Hamdi Tovërlani, nënkryetar
Gafurr Kabashi, nënkryetar
Xhafer Morina, anëtar
Jakup Berisha, anëtar
Bajra Bardhi, anëtar
Bedri Hamza, anëtar
Beqir Aliu, anëtar
Ferat Qorri, anëtar
Enver Nuredini, anëtar
Ajet Shosholli, anëtar
Shinasi Berisha, anëtar
Arbnor Morina, anëtar
Shpëtim Kuqi, anëtar
Bajram Zeqiri, anëtar
Skender Selmani, anëtar
Haki Loci, anëtar
Sejdi Mërkoja, sekretar
`;


const kuvendiIDyte = `
Rexhep Abdullahu, kryetar
Arsim Spahiu
Luan Prekazi
Jashar Peci
Faruk Domi
Qemajl Nallbani
Sami Ahmeti
Halil Kika
Jakup Berisha
Ismet Shurdhani
Flamur Vala
Ali Shkreli, sekretar
`;

const kryesia2000 = `
Sabri Hashani, kryetar
Adem Çollakaj, nënkryetar
Afërditë Fazlija, nënkryetare
Haki Demolli
Blerim Vula
Hamdi Govori
Agim Elshani
Tasin Pajaziti
Skender Ramadani
Refik Hydaverdi
Naim Besimi
Nazmi Kelmendi
Ismajl Kosumi
Hidajete Beqiri
`;

const kryesia2004 = `
Sabri Hashani, kryetar
Agim Ademi, nënkryetar
Haki Demolli, nënkryetar
Hidajete Beqiri
Naser Rexhepi
Luan Prekazi
Hysen Gashi
Skender Berisha
Skender Morina
Refik Hydaverdi
Maliq Lushaku
Nazmi Kelmendi
Ismajl Kosumi
Isuf Ajeti
Isë Govori
Blerim Vula, sekretar i përgjithshëm
`;

const kryesia2006 = `
Sabri Hashani, kryetar
Agim Ademi, nënkryetar
Haki Demolli nënkryetar
Xhelal Broja
Agim Maloku
Luan Vokshi
Shemsedin Muhaxheri
Iljaz Mjaku
Murat Hoxha
Mehmet Shkreli
Isa Govori
Eroll Salihu, sekretar i përgjithshëm
`;

const komitetiEkzekutiv2008 = `
Fadil Vokrri, kryetar
Agim Ademi, nënkryetar
Bekim Haxhiu, nënkryetar
Predrag Joviq, nënkryetar
Agim Maloku
Luan Vokshi
Vjollcë Krasniqi
Xhelal Broja
Murat Hoxha
Iljaz Mjaku
Fisnik Ademi
Bakir Burri
Eroll Salihu, sekretar i përgjithshëm
`;

const komitetiEkzekutiv2012 = `
Fadil Vokrri, kryetar
Agim Ademi, nënkryetar
Bekim Haxhiu, nënkryetar
Predrag Joviq, nënkryetar
Agim Maloku
Luan Vokshi
Vjollcë Krasniqi
Xhelal Broja
Murat Hoxha
Iljaz Mjaku
Bakir Burri
Flamur Bunjaku
Eroll Salihu, sekretar i përgjithshëm
`;

const komitetiEkzekutiv2016 = `
Fadil Vokrri, kryetar
Agim Ademi, nënkryetar
Predrag Jovic, nënkryetar
Vjollca Krasniqi, anëtare
Xhelal Broja, anëtar
Bakir Burri, anëtar
Mirsad Çollaku, anëtar
Ilaz Mjaku, anëtar
Luan Vokshi, anëtar
Flamur Bunjaku, anëtar
Agron Mustafa, anëtar
Bekim Rexhepi
Eroll Salihu, sekretar i përgjithshëm
`;

const komitetiAktual = `
Agim Ademi, kryetar
Bakir Burri, nënkryetar
Agim Dibrani, nënkryetar
Predrag Jovic, nënkryetar
Vjollca Krasniqi, anëtar
Xhelal Broja, anëtar
Ilaz Mjaku, anëtar
Mirsad Çollaku, anëtar
Naser Ajeti, anëtar
Agim Nura, anëtar
Ismajl Ajeti, anëtar
Markus Weidner, u.d. sekretar i përgjithshëm
`;

const items: CollapseProps['items'] = [
  {
    key: '1',
    label: 'Kryesia e parë e FFK-së – 20.08.1991',
    children: (
        <div>
          {kryestiaEPare
              .trim()
              .split('\n')
              .map((member, index) => (
                  <p key={index}>
                    <span className={styles.IndexColor}>{index + 1}.</span> {member}
                  </p>
              ))}
        </div>
    ),
  },
  {
    key: '2',
    label: 'Kuvendi i dytë – Kryesia e FFK-së: 22.08.1994',
    children: (
        <div>
          {kuvendiIDyte
              .trim()
              .split('\n')
              .map((member, index) => (
                  <p key={index}>
                    <span className={styles.IndexColor}>{index + 1}.</span> {member}
                  </p>
              ))}
        </div>
    ),
  },
  {
    key: '3',
    label: 'Kryesia e FFK-së – 2000',
    children: (
        <div>
          {kryesia2000
              .trim()
              .split('\n')
              .map((member, index) => (
                  <p key={index}>
                    <span className={styles.IndexColor}>{index + 1}.</span> {member}
                  </p>
              ))}
        </div>
    ),
  },
  {
    key: '4',
    label: 'Kryesia e FFK-së – 2004',
    children: (
        <div>
          {kryesia2004
              .trim()
              .split('\n')
              .map((member, index) => (
                  <p key={index}>
                    <span className={styles.IndexColor}>{index + 1}.</span> {member}
                  </p>
              ))}
        </div>
    ),
  },
  {
    key: '5',
    label: 'Kryesia e FFK-së – 2006',
    children: (
        <div>
          {kryesia2006
              .trim()
              .split('\n')
              .map((member, index) => (
                  <p key={index}>
                    <span className={styles.IndexColor}>{index + 1}.</span> {member}
                  </p>
              ))}
        </div>
    ),
  },
  {
    key: '6',
    label: 'Komiteti Ekzekutiv i FFK-së – 2008',
    children: (
        <div>
          {komitetiEkzekutiv2008
              .trim()
              .split('\n')
              .map((member, index) => (
                  <p key={index}>
                    <span className={styles.IndexColor}>{index + 1}.</span> {member}
                  </p>
              ))}
        </div>
    ),
  },
  {
    key: '7',
    label: 'Komiteti Ekzekutiv i FFK-së – 2012',
    children: (
        <div>
          {komitetiEkzekutiv2012
              .trim()
              .split('\n')
              .map((member, index) => (
                  <p key={index}>
                    <span className={styles.IndexColor}>{index + 1}.</span> {member}
                  </p>
              ))}
        </div>
    ),
  },
  {
    key: '8',
    label: 'Komiteti Ekzekutiv i FFK-së – 2016',
    children: (
        <div>
          {komitetiEkzekutiv2016
              .trim()
              .split('\n')
              .map((member, index) => (
                  <p key={index}>
                    <span className={styles.IndexColor}>{index + 1}.</span> {member}
                  </p>
              ))}
        </div>
    ),
  },
  {
    key: '9',
    label: 'Komiteti Ekzekutiv aktual i FFK-së',
    children: (
        <div>
          {komitetiAktual
              .trim()
              .split('\n')
              .map((member, index) => (
                  <p key={index}>
                    <span className={styles.IndexColor}>{index + 1}.</span> {member}
                  </p>
              ))}
        </div>
    ),
  },
];

const Kryestie: React.FC = () => <Collapse accordion items={items} />;

export default Kryestie;