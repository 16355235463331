import { Carousel } from "antd";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import styles from "./HeroSection.module.scss";
import { replaceStringSpaces } from "../../utils/utils";

interface Post {
    id: string;
    alTitle: string;
    enTitle: string;
    mainImageUrl: string;
}

const HeroSection = () => {
    const { data: posts, loading, error } = useFetch<Post[]>("/posts/last-three-main-posts");
    const { i18n } = useTranslation();
    const navigate = useNavigate();

    const handleNavigation = useCallback((post: Post) => {
        if (!post) return;
        const stateObj = { state: { ...post } };

        const url =
            i18n.language === "al"
                ? `/lajmet/${replaceStringSpaces(post.alTitle ?? "")}`
                : `/lajmet/${replaceStringSpaces(post.enTitle ?? "")}`;

        navigate(url, stateObj);
    }, [navigate, i18n]);

    if (loading) return <div></div>;
    if (error || !posts) return <div>Error loading posts</div>;

    return (
        <Carousel autoplay>
            {posts.map((post) => (
                <div
                    className={styles.Container}
                    key={post.id}
                    onClick={() => handleNavigation(post)}
                    style={{ cursor: "pointer" }}
                >
                    <img src={post.mainImageUrl} alt={post.enTitle} />
                    <div className={styles.MainNews}>
                        <h1>{i18n.language === "al" ? post.alTitle : post.enTitle}</h1>
                    </div>
                </div>
            ))}
        </Carousel>
    );
};

export default HeroSection;
