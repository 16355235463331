import React from 'react';
import styles from './TermsOfUse.module.scss';

const TermsOfUse = () => {
  return (
      <div className={styles.MainWrapper}>
        <div className={styles.TermsContainer}>
          <h1>FFK - Terms Of Use</h1>
          <div className={styles.ListWrapper}>
            <div>
              <h2>Acceptance of Terms</h2>
              <p>
                By using FFK Website, you agree to be bound by these Terms of Use. If you do not agree to these terms,
                please refrain from using the App.
              </p>
            </div>

            <div className={styles.UseOfTheApp}>
              <h2>Use of the App</h2>
              <p><span>Account Creation:</span> To access certain features of the App, you may be required to create
                an account. You are responsible for maintaining the confidentiality of your account information and
                agree to notify us immediately of any unauthorized use.</p>
              <p><span>User Conduct:</span> You agree not to use the App for any unlawful purpose or in violation of
                any applicable laws. You also agree not to:</p><br/>
              <ul>
                <li><p>Post or transmit any content that is unlawful, harmful, threatening, abusive, or otherwise
                  objectionable (this is specific to ads creation).</p></li>
                <li><p>Attempt to gain unauthorized access to any portion of the Website.</p></li>
              </ul>
            </div>

            <div>
              <h2>Intellectual Property</h2>
              <p><span>Ownership:</span> The App and its original content, features, and functionality are owned by
                FFK and are protected by international copyright, trademark, patent, trade secret, and other
                intellectual property or proprietary rights laws.</p>
              <p><span>License:</span> FFK grants you a limited, non-exclusive, revocable license to use the App for
                your personal, non-commercial purposes and also if you intend to promote something through ads if you
                are a business owner.</p>
            </div>

            <div>
              <h2>Limitation of Liability</h2>
              <p><span>Disclaimer:</span> The App is provided as is without any warranties, express or implied. FFK
                makes no representations or warranties regarding the accuracy or completeness of the content provided in
                the App.</p>
              <p><span>Limitation of Liability:</span> FFK and its affiliates shall not be liable for any direct,
                indirect, incidental, special, or consequential damages arising out of or in any way connected with the
                use of the App.</p>
            </div>

            <div>
              <h2>Termination</h2>
              <p>FFK reserves the right to terminate or suspend your access to the App, without notice, if you breach
                any of these Terms.</p>
            </div>

            <div>
              <h2>Changes to Terms</h2>
              <p>FFK reserves the right to update these Terms of Use at any time. You will be notified of any changes by
                posting the new Terms of Use on this page.</p>
            </div>

            <div>
              <h2>Contact Us</h2>
              <p>If you have any questions or concerns about these Terms of Use, please contact us at <span>info@ffk-kosova.com</span></p>
            </div>

            <div>
              <p>Thank you!</p>
              <p>Last updated: 24.02.2025</p>
            </div>
          </div>
        </div>
      </div>
  );
};

export default TermsOfUse;
