import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import styles from "./CookieConsent.module.scss";

const CookieConsent: React.FC = () => {
    const [showBanner, setShowBanner] = useState(false);

    useEffect(() => {
        const cookieConsent = Cookies.get("cookie_consent");
        if (!cookieConsent) {
            setShowBanner(true);
        }
    }, []);

    const handleAccept = () => {
        Cookies.set("cookie_consent", "accepted", { expires: 365 });
        setShowBanner(false);
    };

    const handleDecline = () => {
        Cookies.set("cookie_consent", "declined", { expires: 365 });
        setShowBanner(false);
    };

    if (!showBanner) return null;

    return (
        <div className={styles.cookieBanner}>
            <p className={styles.cookieText}>
                We use third-party cookies to personalize content and analyze web traffic. By clicking
                <strong> "Accept" </strong>, you agree we can store cookies on your device in accordance with our
                <a href="/privacy-policy"> Privacy Policy</a>.
            </p>
            <div className={styles.buttonContainer}>
                <button onClick={handleDecline} className={styles.declineButton}>Decline</button>
                <button onClick={handleAccept} className={styles.acceptButton}>Accept</button>
            </div>
        </div>
    );
};

export default CookieConsent;
