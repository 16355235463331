import React, {useState} from "react";
import {Form, Input, Button, message} from "antd";
import {useTranslation} from "react-i18next";
import styles from "./ContactForm.module.scss";

const Contact: React.FC = () => {
  const {t} = useTranslation();
  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [form] = Form.useForm();

  const onFinish = async (values: typeof formValues) => {
    try {
      console.log("Form submitted with values:", values);
      message.success(
          t("app.contact.success", "Your message has been sent successfully!")
      );
      form.resetFields();
    } catch (err) {
      console.error("Form submission error:", err);
      message.error(
          t("app.contact.error", "There was an error sending your message.")
      );
    }
  };

  return (
      <div className={styles.MainWrapper}>
        <div className={styles.ContactContainer}>
          <div className={styles.MapContainer}>
            <iframe
                title="Fadil Vokrri Stadium"
                width="100%"
                height="100%"
                style={{border: 0, borderRadius: "8px"}}
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.6293123943997!2d21.155000401140615!3d42.66100510695249!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x13549ee398234689%3A0x1ecdd21bb52cbd0f!2sFadil%20Vokrri%20Stadium!5e0!3m2!1sen!2s!4v1740581938745!5m2!1sen!2s"
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>

          <div className={styles.ContactFormContainer}>
            <h1>{t("app.contact.title", "Na kontaktoni")}</h1>
            <p>{t("app.contact.description", "Plotësoni formularin më poshtë për të na kontaktuar. ")}</p>
            <Form onFinish={onFinish} initialValues={formValues} form={form}>
              <div className={styles.ContactForm}>
                <div className={styles.FormGroup}>
                  <p>{t("app.contact.name", "Emri & Mbiemri")}</p>
                  <Form.Item
                      name="name"
                      rules={[{required: true, message: t("app.validations.required", "Required")}]}
                  >
                    <Input
                        value={formValues.name}
                        onChange={(e) => setFormValues({...formValues, name: e.target.value})}
                    />
                  </Form.Item>
                </div>
                <div className={styles.FormGroup}>
                  <p>{t("app.contact.email", "Email")}</p>
                  <Form.Item
                      name="email"
                      rules={[
                        {required: true, message: t("app.validations.required", "Required")},
                        {type: "email", message: t("app.contact.invalidEmail", "Invalid email format")}
                      ]}
                  >
                    <Input
                        type="email"
                        value={formValues.email}
                        onChange={(e) => setFormValues({...formValues, email: e.target.value})}
                    />
                  </Form.Item>
                </div>
                <div className={styles.FormGroup}>
                  <p>{t("app.contact.message", "Mesazhi")}</p>
                  <Form.Item
                      name="message"
                      rules={[{required: true, message: t("app.validations.required", "Required")}]}
                  >
                    <Input.TextArea
                        rows={4}
                        value={formValues.message}
                        onChange={(e) => setFormValues({...formValues, message: e.target.value})}
                    />
                  </Form.Item>
                </div>
              </div>
              <div className={styles.ButtonAlignment}>
                <Button type="default" htmlType="submit" className={styles.SendButton}>
                  {t("app.contact.submit", "Dërgo")}
                </Button>
              </div>
            </Form>
          </div>
        </div>
        <div className={styles.InfoContainer}>
          <div>
            <h2>Adresa</h2>
            <p>Rruga “28 Nëntori”, nr. 171</p>
            <p>Prishtinë 10000, Kosovë</p>
          </div>
          <div>
            <h2>Administrata</h2>
            <p><span>tel: </span> +383 38 600 220</p>
            <p><span>fax: </span> +383 38 548 560</p>
            <p><span>e-mail: </span> info@ffk-kosova.com</p>
          </div>
          <div>
            <h2>Zyra për media</h2>
            <p><span>tel: </span> +383 48 101 333</p>
            <p><span>e-mail: </span> press@ffk-kosova.com</p>
            <p><span>e-mail: </span> makibajrami@ffk-kosova.com</p>
          </div>
          <div>
            <h2>Zyra për marketing</h2>
            <p><span>tel: </span> +383 48 101 222</p>
            <p><span>e-mail: </span> marketing@ffk-kosova.com</p>
            <p><span>e-mail: </span> albamorina@ffk-kosova.com</p>
          </div>
        </div>
      </div>
  );
};

export default Contact;
