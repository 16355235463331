import React, { useCallback, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Pagination } from "antd";
import useFetchSearch from "../../hooks/useFetchSearch";
import NewsCard from "../../components/_shared/news-card/NewsCard";
import DataNotFound from "../../components/_shared/data-not-found/DataNotFound";
import styles from "./SearchResults.module.scss";

const pageSize = 6;

const SearchResults = () => {
  const [searchParams] = useSearchParams();
  const query = searchParams.get("s") || "";

  const [language, setLanguage] = useState(() => {
    return localStorage.getItem("language") || "al";
  });

  const [currentPage, setCurrentPage] = useState(1);

  const fetchParams = useMemo(() => ({
    query,
    language,
    pageable: {
      page: currentPage - 1,
      size: pageSize,
      sort: ["string"],
    },
  }), [query, language, currentPage]);

  const { data, loading, error } = useFetchSearch(fetchParams.query, fetchParams.language, fetchParams.pageable);

  const paginatedNews = useMemo(() => data?.content || [], [data]);
  const totalPages = data?.totalElements ? Math.ceil(data.totalElements / pageSize) : 1;

  const handlePageChange = useCallback((page: number) => {
    setCurrentPage(page);
  }, []);

  return (
      <div className={styles.SearchContainer}>
        <h2 style={{ marginTop: "4rem" }}>Search Results for "{query}"</h2>

        {loading && <p>Loading...</p>}
        {error && <p>Error: {error.message}</p>}
        {data?.content.length === 0 && <DataNotFound loading={loading} />}

        <div className={styles.NewsGrid}>
          {paginatedNews.map((post: any) => (
              <NewsCard key={post.id} {...post} />
          ))}
        </div>

        {totalPages > 1 && (
            <Pagination
                current={currentPage}
                pageSize={pageSize}
                total={data?.totalElements}
                onChange={handlePageChange}
                className={styles.Pagination}
            />
        )}
      </div>
  );
};

export default SearchResults;
