import DOMPurify from "dompurify";

export function formatDate(dateStr: string) {
    const date = new Date(dateStr.replace("Z", ""));
    return date.toLocaleString("en-GB", {
        day: "numeric",
        month: "long",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
    });
}

export function replaceStringSpaces(str: string) {
  return str.replace(/\s+/g, "-");
}

export const splitTextByDelimiter = (text: string, delimiter: string = "\n") => {
    return text
        .split(delimiter)
        .map((section) => section.trim())
        .filter((section) => section !== "");
};

export const splitTextByDelimiter2 = (htmlString: any) => {
    if (!htmlString) return [];

    // Split by new lines while keeping formatting
    return htmlString.split("\n").map((section: any) => DOMPurify.sanitize(section.trim()));
};

export const  parseLanguageLocaleString = (locale: any): string => {
    const defaultLocale = "al";
    if(locale) {
        return locale;
    } else {
        return defaultLocale;
    }
}