import React from 'react';
import type { CollapseProps } from 'antd';
import { Collapse } from 'antd';
import styles from "./FFKHistory.module.scss";

const kampionet1945  = ` 
1945 Jedinstvo (Prishtinë)
1946 Jedinstvo
1947 Trepça (Mitrovicë)
1947-48 Proleteri (Prishtinë)
1948-49 Trepça
1950 Trepça
1951 Kosova (Prishtinë)
1952 Trepça
1953-54 Kosova
1954-55 Trepça
1955-56 Rudari (Stari Tërg)
1956-57 Rudniku (Hajvali)
1957-58 Rudari
1958-59 Prishtina
1959-60 Rudari
1960-61 Prishtina
1961-62 Buduqnosti (Pejë)
1962-63 Cërvena Zvezda (Gjilan)
1963-64 Slloga (Lipjan)
1964-65 Slloga
1965-66 Buduqnosti
1966-67 Obiliqi (Obiliq)
1967-68 Vëllaznimi (Gjakovë)
1968-69 Vëllaznimi
1969-70 Vëllaznimi
1970-71 Vëllaznimi
1971-72 Obiliqi
1972-73 Fushë-Kosova
1973-74 Vëllaznimi
1974-75 Liria (Prizren)
1975-76 KEK Kosova (Obiliq)
1976-77 Prishtina
1977-78 Buduqnosti
1978-79 Prishtina
1979-80 Vëllaznimi
1980-81 Liria
1981-82 Vëllaznimi
1982-83 KNI ”Ramiz Sadiku” (Prishtinë)
1983-84 Liria
1984-85 Cërvena Zvezda
1985-86 Vëllaznimi
1986-87 Liria
1987-88 Trepça”
1988-89 Buduqnosti
1989-90 Vëllaznimi
1990-91 Fushë-Kosova
`;

const items: CollapseProps['items'] = [
  {
    key: '1',
    label: 'Kampionët e Ligës së Kosovës nga viti 1945',
    children: (
        <div>
          {kampionet1945
              .trim()
              .split('\n')
              .map((member, index) => (
                  <p key={index}>
                    <span className={styles.IndexColor}>{index + 1}.</span> {member}
                  </p>))}
        </div>
    ),
  }
]

const Kampionet1945: React.FC = () => <Collapse accordion items={items}/>;

export default Kampionet1945;