import React from 'react';
import type { CollapseProps } from 'antd';
import { Collapse } from 'antd';
import styles from "./FFKHistory.module.scss";

const kampionet1991 = `
1991-92 Prishtina  
1992-93 Trepça  
1993-94 Dukagjini (Klinë)  
1994-95 Liria  
1995-96 Prishtina  
1996-97 Prishtina  
1997-98 Kampionati, për shkak të luftës, ndërpritet pas përfundimit të stinorit vjeshtor  
1998-99 Për shkak të luftës, kampionati nuk u zhvillua fare.  
1999-2000 Prishtina  
2000-01 Prishtina  
2001-02 Besiana  
2002-03 Drita (Gjilan)  
2003-04 Prishtina  
2004-05 Besa (Pejë)  
2005-06 Besa  
2006-07 Besa  
2007-08 Prishtina  
2008-09 Prishtina  
2009-10 Trepça  
2010-11 Hysi  
2011-12 Prishtina  
2012-13 Prishtina  
2013-14 Llamkos Kosova  
2014-15 Feronikeli  
2015-16 Feronikeli  
2016-17 Trepça’89  
2017-18 Drita  
2018-19 Feronikeli  
2019-20 Drita  
2020-21 Prishtina  
2021-22 Ballkani  
2022-23 Ballkani  
`;


const fituesitEKupes  = `
1991/92: Trepça (pa lojë)  
1992/93: Flamurtari (në finale e mundi Trepçën 1:0)  
1993/94: Prishtina (në finale e mundi Vëllaznimin 2:1)  
1994/95: Prishtina (në finale e mundi Dukagjinin 1:0)  
1995/96: Flamurtari (në finale e mundi Dukagjinin 2:1)  
1996/97: 2 Korriku (pa lojë, Gjilani nuk u paraqit)  
1999/00: Gjilani (në finale e mundi Besianën 1:0)  
2000/01: Drita (në finale e mundi Gjilanin 1:1 – 6:5 pen)  
2001/02: Besiana (në finale e mundi Gjilanin 2:1)  
2002/03: KEK-u (në finale e mundi Prishtinën 3:1)  
2003/04: Kosova Pr. (në finale e mundi Besën 1:0)  
2004/05: Besa (në finale e mundi KEK-un 3:2)  
2005/06: Prishtina (në finale e mundi Drenicën 1:1 – 5:4 pen)  
2006/07: Liria (në finale e mundi Flamurtarin 0:0 – 3:0 pen)  
2007/08: Vëllaznimi (në finale e mundi Trepçën’89 2:0)  
2008/09: Hysi (në finale e mundi Prishtinën 2:1)  
2009/10: Liria (në finale e mundi Vëllaznimin 2:1)  
2010/11: Besa (në finale e mundi Prishtinën 2:1)  
2011/12: Trepça ’89 (në finale e mundi Ferizajn 3:0)  
2012/13: Prishtina (në finale e mundi Ferizajn 1:1 – 4:3 pen)  
2013/14: Feronikeli (në finale e mundi Hajvalinë 2:1)  
2014/15: Feronikeli (në finale e mundi Trepçën’89 1:1 – 5:4 pen)  
2015/16: Prishtina (në finale e mundi Dritën 2:1)  
2016/17: Besa (në finale e mundi Llapin 1:1 – 4:2 pen)  
2017/18: Prishtina (në finale e mundi Vëllaznimin 1:1 – 5:4 pen)  
2018/19: Feronikeli (në finale e mundi Trepçën’89 5:1)  
2019/20: Prishtina (në finale e mundi Ballkanin 1:0)  
2020/21: Llapi (në finale e mundi Dukagjinin 1:1 – pen. 4:3)  
2021/22: Llapi (në finale e mundi Dritën 2:1)  
2022/23: Prishtina (në finale e mundi Gjilanin 2:0)  

`;

const items: CollapseProps['items'] = [
  {
    key: '1',
    label: 'Kampionët e Kosovës nga viti 1991',
    children: (
        <div>
          {kampionet1991
              .trim()
              .split('\n')
              .map((member, index) => (
                  <p key={index}>
                    <span className={styles.IndexColor}>{index + 1}.</span> {member}
                  </p>))}
        </div>
    ),
  },
  {
    key: '2',
    label: 'Fituesit e Kupës së Kosovës që nga mëvetësimi',
    children: (
        <div>
          {fituesitEKupes
              .trim()
              .split('\n')
              .map((member, index) => (
                  <p key={index}>
                    <span className={styles.IndexColor}>{index + 1}.</span> {member}
                  </p>
              ))}
        </div>

    ),
  }
]

const Kampionet: React.FC = () => <Collapse accordion items={items}/>;

export default Kampionet;