import React, { useMemo } from "react";
import { useLocation } from "react-router-dom";
import styles from "./PlaymakersGlassroots.module.scss";
import DUMMYIMAGE from "./../../assets/images/grassroots.jpg";
import useFetch from "../../hooks/useFetch";
import NewsCard from "../../components/_shared/news-card/NewsCard";
import DataNotFound from "../../components/_shared/data-not-found/DataNotFound";

const PlaymakersGrassroots = () => {
  const { pathname, state } = useLocation();

  const { data, loading } = useFetch<any>(
    `/posts?subCategoryId=${state?.category?.id}&childSubcategoryId=${state?.subCategory?.id}`,
  );

  console.log("datat", data);

  const title = useMemo(() => {
    return pathname.substring(1);
  }, [pathname]);

  return (
    <div className={styles.MainWrapper}>
      <div className={styles.PrimaryTitleWrapper}>
        <h1>{title}</h1>
        <h4>
          Explore our latest {title} activities and see what we've been up to.
          Stay tuned for new updates and highlights!
        </h4>
      </div>
      {data && data.content.length > 0 ? (
        <div className={styles.ContentWrapper}>
          <img src={DUMMYIMAGE} alt="" className={styles.MainImage} />
          <div className={styles.AllNews}>
            {data?.content.map((newsItem: any, index: any) => (
              <NewsCard key={index} {...newsItem} />
            ))}
          </div>
        </div>
      ) : (
        <DataNotFound loading={loading} />
      )}
    </div>
  );
};

export default PlaymakersGrassroots;
