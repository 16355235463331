import React, { useEffect, useMemo, useState } from "react";
import styles from "./Competition.module.scss";
import { useLocation, useParams } from "react-router-dom";
import AlbiMallSuperligaImage from "../../assets/images/albi-mall-superliga.png";
import RaiffaisenLigaImage from "../../assets/images/Raiffeisen-Liga-e-pare- Baneri.png";
import BpbLigaImage from "../../assets/images/bpbLigaImage.png";
import SiguriaSuperligaImage from "../../assets/images/SiguriaSuperligaLogo.png";
import logo from "../../assets/images/ffk-logo.png";
import {Avatar, Badge, Space, Table} from "antd";
import useFetch from "../../hooks/useFetch";
import Spinner from "../../components/_shared/loader/Spinner";

const Competition = () => {
  const { title } = useParams();
  const { state } = useLocation();

  const [weeks, setWeeks] = useState<number[]>([]); // Dynamic weeks
  const [matches, setMatches] = useState<any[]>([]); // All matches

  const referenceLeagueId = state?.categoryId;

  const {
    data: standingsData,
    loading: standingsLoading,
    error: standingsError,
  } = useFetch<any>(`/standings/league/${referenceLeagueId}`);

  const {
    data: weeksData,
    loading: weeksLoading,
    error: weeksError,
  } = useFetch<any>(`/matches/${referenceLeagueId}/completed-gameweeks`);

  useEffect(() => {
    if (weeksData) {
      setWeeks(weeksData);
    }
  }, [weeksData]);

  useEffect(() => {
    if (weeks.length > 0) {
      Promise.all(
          weeks.map((weekNumber) =>
              fetch(process.env.REACT_APP_API_URL + `/matches/${referenceLeagueId}/week/${weekNumber}`)
                  .then((res) => res.json())
                  .then((data) =>
                      data.map((match: any) => ({ ...match, weekNumber }))
                  )
          )
      )
          .then((allMatches) => setMatches(allMatches.flat()))
          .catch((error) => console.error("Error loading matches:", error));
    }
  }, [weeks, referenceLeagueId]);

  const formattedTitle = useMemo(() => {
    return title?.replace(/-/g, " ").toUpperCase();
  }, [title]);

  const standingsColumns = [
    {
      title: "#",
      dataIndex: "index",
      key: "index",
      render: (_: any, __: any, index: number) => index + 1,
    },
    {
      title: "Club",
      dataIndex: "teamName",
      key: "teamName",
      render: (text: string, record: any) => (
          <div className={styles.ClubsColumn}>
            <Avatar
                shape="square"
                size="small"
                icon={<img src={record.logoUrl} alt="club" />}
            />
            <span>{text}</span>
          </div>
      ),
    },
    { title: "MP", dataIndex: "matchesPlayed", key: "matchesPlayed" },
    { title: "W", dataIndex: "wins", key: "wins" },
    { title: "D", dataIndex: "draws", key: "draws" },
    { title: "L", dataIndex: "losses", key: "losses" },
    { title: "GF", dataIndex: "goalsScored", key: "goalsScored" },
    { title: "GA", dataIndex: "goalsAgainst", key: "goalsAgainst" },
    { title: "GD", dataIndex: "goalDifference", key: "goalDifference" },
    {
      title: "Pts",
      dataIndex: "points",
      key: "points",
      render: (pts: number) => <span style={{ color: "green" }}>{pts}</span>,
    },
  ];

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    const formattedDate = new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    }).format(date);
    const formattedTime = date.toTimeString().slice(0, 5);
    return `${formattedDate} - ${formattedTime}`;
  };

  const matchesColumns = [
    {
      key: "dateLeague",
      render: (record: any) => (
          <div className={styles.MatchCard}>
            <div className={styles.MatchInfo}>
              <span>{formatDate(record.date)}</span>
              <h4>{record.leagueName}</h4>
            </div>
            <div className={styles.MatchTeams}>
              <div className={styles.Team}>
                <img
                    src={record.homeTeamLogo || logo}
                    alt={record.homeTeamName}
                />
                <span>{record.homeTeamName}</span>
              </div>
              <div className={styles.Score}>
                {record.status !== 'UPCOMING'
                    ? `${record.homeTeamScore} - ${record.awayTeamScore}`
                    : "VS"}
              </div>
              <div className={styles.Team}>
                <img
                    src={record.awayTeamLogo || logo}
                    alt={record.awayTeamName}
                />
                <span>{record.awayTeamName}</span>
              </div>

              {record.status === "POSTPONED" && (
                  <div className={styles.PostponedBadge}>
                    <Badge color="red" text="POSTPONED" />
                  </div>
              )}
            </div>
          </div>
      ),
    },
  ];

  if (standingsLoading || weeksLoading) {
    return <Spinner />;
  }

  if (standingsError || weeksError) {
    return <div>Error loading data.</div>;
  }

  return (
      <div className={styles.MainWrapper}>
        <div className={styles.PrimaryTitleWrapper}>
          <h1>{formattedTitle}</h1>
        </div>
        <div className={styles.ContentWrapper}>
          {formattedTitle === "ALBI MALL SUPERLIGA" ? (
              <img src={AlbiMallSuperligaImage} alt="albimall-league-image" />
          ) : formattedTitle === "RAIFFEISEN LIGA E PARË – GRUPI A" ||  formattedTitle === "RAIFFEISEN LIGA E PARË – GRUPI B" ? (
              <img src={RaiffaisenLigaImage} alt="raiffaisen-league-image" />
          ) : formattedTitle === "LIGA U17 – FEMRAT – GRUPI A" ||  formattedTitle === "LIGA U17 – FEMRAT – GRUPI B" ? (
              <img src={BpbLigaImage} alt="bpb-league-image" />
          ) : formattedTitle === "SIGURIA SUPERLIGA – FEMRAT" ? (
              <img src={SiguriaSuperligaImage} alt="siguria-league-image" />
          ) :
              null
          }


          <div style={{marginTop: "3rem"}}>
            <div
                style={{
                  float: "left",
                  textAlign: "left",
                  marginBottom: "1rem",
                }}
            >
              <h2>{formattedTitle}</h2>
              <p>Tabela</p>
            </div>
            <div className={styles.TableContent}>
              <Table
                  columns={standingsColumns}
                  dataSource={standingsData}
                  pagination={false}
                  rowClassName={(_, index) => {
                    if (formattedTitle === "RAIFFEISEN LIGA E PARË – GRUPI A") {
                      if (index === 0) return "first-row";
                      if (index === 1) return "penultimate-row";
                      if (index >= standingsData.length - 2) return "bottom-two";
                    } else if (formattedTitle === "RAIFFEISEN LIGA E PARË – GRUPI B") {
                      if (index === 0) return "first-row";
                      if (index === 1) return "penultimate-row";
                      if (index >= standingsData.length - 2) return "bottom-two";
                    } else if (formattedTitle === "LIGA E DYTË") {
                      if (index === 0) return "first-row";
                      if (index === 1) return "first-row";
                      if (index >= standingsData.length - 2) return "bottom-two";
                    } else if (formattedTitle === "ALBI MALL SUPERLIGA") {
                      if (index === 0) return "first-row";
                      if (index === 1 || index === 2) return "second-third-rows";
                      if (index === standingsData.length - 3) return "penultimate-row";
                      if (index >= standingsData.length - 2) return "bottom-two";
                    }
                    return "";
                  }}
              />
              {formattedTitle === "ALBI MALL SUPERLIGA" && (
                  <Space>
                    <Badge color="#08a045" text={"Champions League"} />
                    <Badge color="#87dd6b" text={"Conference League"} />
                    <Badge color="#ff7a33" text={"Barazh"} />
                    <Badge color="#ef4444" text={"Rënie nga liga"} />
                  </Space>
              )}
              {formattedTitle === "RAIFFEISEN LIGA E PARË – GRUPI A" && (
                  <Space>
                    <Badge color="#08a045" text={"Superliga"} />
                    <Badge color="#ff7a33" text={"Play-Off/Barazh"} />
                    <Badge color="#ef4444" text={"Rënie në Ligë të Dytë"} />
                  </Space>
              )}
              {formattedTitle === "RAIFFEISEN LIGA E PARË – GRUPI B" && (
                  <Space>
                    <Badge color="#08a045" text={"Superliga"} />
                    <Badge color="#ff7a33" text={"Play-Off/Barazh"} />
                    <Badge color="#ef4444" text={"Rënie në Ligë të Dytë"} />
                  </Space>
              )}
              {formattedTitle === "LIGA E DYTË" && (
                  <Space>
                    <Badge color="#08a045" text={"Liga e Parë"} />
                    <Badge color="#ef4444" text={"Rënie nga liga"} />
                  </Space>
                )}
            </div>
          </div>

          <div className={styles.MainWrapper}>
            <div className={styles.MatchesWrapper}>
              {weeks.map((weekNumber) => (
                  <div key={weekNumber} className={styles.MatchesContainer}>
                    <h2>Java e {weekNumber}</h2>
                    <Table
                        columns={matchesColumns}
                        dataSource={matches.filter(
                            (match) => match.weekNumber === weekNumber
                        )}
                        pagination={false}
                        rowKey={(record) => record.id}
                    />
                  </div>
              ))}
            </div>
          </div>
        </div>
      </div>
  );
};

export default Competition;
