import React from "react";
import { Badge, Card, Col, Row } from "antd";
import styles from "./CompetitionGameCard.module.scss";

interface CompetitionGameCardProps {
  date: string;
  homeTeamName: string;
  homeTeamLogo: string;
  awayTeamName: string;
  awayTeamLogo: string;
  homeTeamScore: number;
  awayTeamScore: number;
  status: string;
}

const CompetitionGameCard: React.FC<CompetitionGameCardProps> = ({
  date,
  homeTeamName,
  awayTeamName,
  homeTeamScore,
  awayTeamScore,
  homeTeamLogo,
  awayTeamLogo,
  status,
}) => {
  const formattedDate = new Date(date).toLocaleString("en-GB", {
    day: "2-digit",
    month: "short",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });

  return (
    <div>
      <Badge
        className={styles.Badge}
        count={status}
      />
      <Card className={styles.CompetitionGameCardContainer}>
        <p className={styles.GameDate}>{formattedDate}</p>
        <Row
          justify="center"
          align="middle"
        >
          <Col>
            <div className={styles.TeamSection}>
              {homeTeamLogo ? (
                <img src={homeTeamLogo} alt={`${homeTeamName} logo`} />
              ) : null}
              <p className={styles.TeamName}>{homeTeamName}</p>
            </div>
          </Col>
          {status === 'FINISHED' ? (
            <Col className={styles.Result}>
              <p>
                {homeTeamScore}
                <span>-</span>
                {awayTeamScore}
              </p>
            </Col>
          ) : (
            <Col className={styles.Result}>
              <p>VS</p>
            </Col>
          )}
          <Col>
            <div className={styles.TeamSection}>
              {awayTeamLogo ? (
                <img src={awayTeamLogo} alt={`${awayTeamName} logo`} />
              ) : null}
              <p className={styles.TeamName}>{awayTeamName}</p>
            </div>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default CompetitionGameCard;
