import React from 'react';
import styles from './Partners.module.scss';
import Teb from "../../assets/images/teb-logo.png";
import ArtMotion from "../../assets/images/artmotion-logo.png";
import Errea from "../../assets/images/errea-logo.png";
import Raiffaisen from "../../assets/images/raiffaisen-logo.png";
import Siguria from "../../assets/images/siguria-logo.png";
import Linkplus from "../../assets/images/linkplus-logo.png";
import Emerald from "../../assets/images/emerald-logo.png";
import AlbiMall from "../../assets/images/albimall-logo.png";
import Bpb from "../../assets/images/bpb-logo.png";
import HibPetrol from "../../assets/images/hib-logo.png";
import Keds from "../../assets/images/keds-logo.png";

const Partners = () => {
  const partnerLogos = [
    { src: ArtMotion, alt: "Art Motion", url: "https://artmotion.net/" },
    { src: AlbiMall, alt: "Albi Mall", url: "https://www.facebook.com/albimall/" },
    { src: Linkplus, alt: "Linkplus IT", url: "https://linkplus-it.com/" },
    { src: Emerald, alt: "Emerald", url: "https://emeraldhotel.info/" },
    { src: Errea, alt: "Errea", url: "https://www.errea.com/" },
    { src: Siguria, alt: "Siguria", url: "https://ks-siguria.com/" },
    { src: Raiffaisen, alt: "Raiffaisen Bank", url: "https://www.raiffeisen-kosovo.com/sq/individe.html" },
    { src: Teb, alt: "Teb", url: "https://teb-kos.com/" },
    { src: Bpb, alt: "BPB", url: "https://www.bpbbank.com/en/" },
    { src: HibPetrol, alt: "Hib Petrol", url: "https://www.hibpetrol.com/" },
    { src: Keds, alt: "KEDS", url: "https://www.keds-energy.com/" }
  ];

  return (
      <div className={styles.PartnersContainer}>
        <div className={styles.Marquee}>
          <div className={styles.MarqueeContent}>
            {[...partnerLogos, ...partnerLogos, ...partnerLogos].map((logo, index) => (
                <img
                    key={index}
                    src={logo.src}
                    alt={logo.alt}
                    onClick={() => window.open(logo.url, '_blank')}
                    style={{ cursor: 'pointer' }}
                />
            ))}
          </div>
        </div>
      </div>
  );
};

export default Partners;
