import React from "react";
import styles from "./Administration.module.scss";
import CardRow from "../../components/_shared/card-row/CardRow";

const AdministrationData = [
  {
    title: "DEPARTAMENTI TEKNIK",
    email: "departamentiteknik@ffk-kosova.com",
    items: [
      { title: "Kombëtarja U-15", email: "u-15@ffk-kosova.com" },
      { title: "Kombëtarja U-17", email: "u-17@ffk-kosova.com" },
      { title: "Kombëtarja U-19", email: "u-19@ffk-kosova.com" },
      { title: "Kombëtarja U-21", email: "u-21@ffk-kosova.com" },
      {
        title: "Kombëtarja Femrat",
        email: "perfaqesuesjafemrat@ffk-kosova.com",
      },
      { title: "Kombëtarja Futsall", email: "futsall@ffk-kosova.com" },
    ],
  },
  {
    title: "DEPARTAMENTI I GARAVE",
    email: "",
    items: [
      {
        title: "Superliga, Liga e parë dhe U21",
        email: "agronshosholli@ffk-kosova.com",
      },
      {
        title: "Liga e dytë dhe Superliga U17",
        email: "bakiberisha@ffk-kosova.com",
      },
      {
        title: "Superliga U19 dhe Liga e parë U19",
        email: "valonsadiku@ffk-kosova.com",
      },
      {
        title: "Superliga, Liga e parë dhe U17-vajzat",
        email: "bujarabdullahu@ffk-kosova.com",
      },
    ],
  },
  { title: "DEPARTAMENTI LIGJOR", email: "legal@ffk-kosova.com" },
  {
    title: "DEPARTAMENTI I MEDIAVE DHE MARKETINGUT",
    email: "marketing@ffk-kosova.com / press@ffk-kosova.com",
  },
  {
    title: "DEPARTAMENTI I REGJISTRIMIT",
    email: "dardansadiku@ffk-kosova.com",
  },
  {
    title: "DEPARTAMENTI I FINANCAVE",
    email: "financat@ffk-kosova.com",
  },
];

const Administration = () => {
  return (
    <div className={styles.MainWrapper}>
      <div className={styles.PrimaryTitleWrapper}>
        <h1>Administrata</h1>
      </div>
      <div className={styles.ListWrapper}>
        {AdministrationData.map((data) => (
          <div>
            <CardRow
              data={{
                title: data.title,
                navigationLink: {
                  link: data.email && `${data.email}`,
                  onLinkClick: () => undefined,
                },
              }}
              colors="BLUE"
              withoutIcon
            />
            {data.items?.map((item) => (
              <div className={styles.ListItem}>
                <p>{item.title}</p>
                {item.email && (
                  <span>
                    {item.email}
                  </span>
                )}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Administration;
