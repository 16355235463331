import React from "react";
import styles from "./Footer.module.scss";
import ffkLogo from "../../assets/images/ffk-logo-sm.png";
import facebook from "../../assets/images/facebook-icon.png";
import instagram from "../../assets/images/instagram-icon.png";
import youtube from "../../assets/images/youtube-icon.png";
import twitter from "../../assets/images/twitter.png";
import {UpOutlined} from "@ant-design/icons";
import Raporto from "../raporto/Raporto";
import {Link} from "react-router-dom";
import {Tooltip} from "antd";

const Footer = () => {

  const scrollToTop = () => {
    window.scrollTo({top: 0, behavior: "smooth"});
  };


  return (
      <div className={styles.Footer}>
        <div className={styles.FooterLogo}>
          <img src={ffkLogo} alt="ffk logo"/>
        </div>
        <div className={styles.GeneralInfo}>
          <div className={styles.Info}>
            <h2>Contact Number</h2>
            <p>+383 38 600 220</p>
          </div>
          <div className={styles.Info}>
            <h2>Email Address</h2>
            <p>info@ffk-kosova.com</p>
          </div>
          <div className={styles.Info}>
            <h2>Location</h2>
            <p>Rruga “28 Nëntori”, Nr. 171</p>
          </div>
        </div>

        <div className={styles.FooterTrademark}>
          <p>
            © 2024 Football Federation of Kosovo. are registered trademarks of
            Football Federation of Kosovo. The team names, logos and uniform
            designs are registered trademarks of the teams indicated. All other
            FFK-related trademarks are trademarks of Football Federation of Kosovo
          </p>
          <div>
            <button className={styles.ScrollToTop} onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>
              <UpOutlined/>
            </button>
              <Raporto/>
          </div>
        </div>

        <div className={styles.FooterNav}>
          <div className={styles.FooterNavLinks}>
            <Link to="/contact">Contact</Link>
            <Link to="/privacyPolicy">Privacy Policy</Link>
            <Link to="/termsOfUse">Terms of Use</Link>
          </div>
          <div className={styles.FederataTitle}>
            <p>Federata e Futbollit e Kosovës</p>
          </div>
          <div className={styles.SocialMediaIcons}>
            <a href="https://www.facebook.com/FFKKosova" target="_blank" rel="noopener noreferrer">
              <img src={facebook}/>
            </a>
            <a href="https://www.instagram.com/FFK_KOS/?fbclid=IwY2xjawGrD9JleHRuA2FlbQIxMAABHT-HThdI7dDvTfuofH1zxpB40lxhyV7kX-BvOeW_u7TTZq15avcLD9ma-w_aem_bvwGRx3JmF5mRiIkI0Segw#"
               target="_blank" rel="noopener noreferrer">
              <img src={instagram}/>
            </a>
            <a href="https://www.youtube.com/c/FederataeFutbolliteKosov%C3%ABs" target="_blank"
               rel="noopener noreferrer">
              <img src={youtube}/>
            </a>
            <a href="https://twitter.com/FFK_KS" target="_blank" rel="noopener noreferrer">
              <img src={twitter}/>
            </a>
          </div>
        </div>

      </div>
  );
};

export default Footer;
