import President from "./../../assets/images/presidenti.jpeg";
import Default from "./../../assets/images/image-commission-background.png";

type CommitteeMember = {
  title: string;
  subTitle: string;
  image: string;
};

export type CommitteeDataType = {
  [key: string]: CommitteeMember[];
};

export const CommitteeData: CommitteeDataType = {
  presidenti: [
    { title: "Agim Ademi", subTitle: "President", image: President },
  ],
  nënkryetarët: [
    {
      title: "Bakir Burri",
      subTitle: "Nënkryetar",
      image: Default,
    },
    {
      title: "Agim Dibrani",
      subTitle: "Nënkryetar",
      image: Default,
    },
    {
      title: "Predrag Joviq",
      subTitle: "Nënkryetar",
      image: Default,
    },
  ],
  anëtarët: [
    {
      title: "Vjollca Krasniqi",
      subTitle: "Anëtar",
      image: Default,
    },
    {
      title: "Xhelal Broja",
      subTitle: "Anëtar",
      image: Default,
    },
    {
      title: "Ilaz Mjaku",
      subTitle: "Anëtar",
      image: Default,
    },
    {
      title: "Mirsad Collaku",
      subTitle: "Anëtar",
      image: Default,
    },
    {
      title: "Ismajl Ajeti",
      subTitle: "Anëtar",
      image: Default,
    },
    {
      title: "Naser Ajeti",
      subTitle: "Anëtar",
      image: Default,
    },
    {
      title: "Agim Nura",
      subTitle: "Anëtar",
      image: Default,
    },
  ],
};
