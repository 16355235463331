import React from "react";
import styles from "./Normative.module.scss";
import CardRow from "../../components/_shared/card-row/CardRow";

const NormativeActs_data = [
  {
    title: "Statuti – akti Themelor",
    navigationLink: {
      title: "lexo dokument",
      link: "https://minio.linkplus.dev:9000/ffk/PDFs/STATUTI%20I%20FFK-se%2005%20SHKURT%202025_0001.pdf",
    },
  },
  {
    title: "Rregullorja e Garave e Superligës dhe Kupës edicioni 2024/2025",
    navigationLink: {
      link: "https://minio.linkplus.dev:9000/ffk/PDFs/Rregullorja%20e%20Garave%20te%20Superliges%20dhe%20Kupes,%20Edicioni%202024%20-%202025.pdf",
    },
  },
  {
    title: "Rregullorja e Garave e Ligës së Parë dhe Kupës edicioni 2024/2025",
    navigationLink: {
      link: "https://minio.linkplus.dev:9000/ffk/PDFs/Rregullorja%20e%20Garave%20te%20LIGA%20E%20PARE%20dhe%20Kupes,%20Edicioni%202024%20-%202025%20.pdf",
    },
  },
  {
    title: "Rregullorja e Garave e Ligës së Dytë dhe Kupës edicioni-2024/2025",
    navigationLink: {
      link: "https://minio.linkplus.dev:9000/ffk/PDFs/Rregullorja%20e%20Garave%20te%20LIGA%20E%20DYTE%20dhe%20Kupes,%20Edicioni%202024%20-%202025%20.pdf",
    },
  },
  {
    title: "Rregullorja e Garave e Superligës U21 edicioni 2024/2025",
    navigationLink: {
      link: "https://minio.linkplus.dev:9000/ffk/PDFs/RREGULLORE%20E%20GARAVE%20TE%CC%88%20SUPERLIGE%CC%88S%20U%2021%20-Edicioni%202024-2025.pdf",
    },
  },
  {
    title:
      "Rregullorja e Garave për ligat U-9, U-11, U-13, U-15, U-17 dhe U-19 edicioni 2024/2025",
    navigationLink: {
      link: "https://minio.linkplus.dev:9000/ffk/PDFs/Rregullorja%20e%20Garave%20te%20U%209%20U%2011%20U%2013%20U%2015%20U%2017%20U%2019,%20Edicioni%202024%20-%202025%20.pdf",
    },
  },
  {
    title: "Kodi i Disiplinës dhe Etikës 2024",
    navigationLink: {
      link: "https://minio.linkplus.dev:9000/ffk/PDFs/Kodi%20i%20Disiplines%20dhe%20Etikes%202024.pdf",
    },
  },
  {
    title: "Protokolli i VAR-it",
    navigationLink: {
      link: "https://minio.linkplus.dev:9000/ffk/PDFs/3.-PROTOKOLI-I-VAR-it-2.pdf",
    },
  },
  {
    title: "Propozicionet e garave për ligat e vajzave",
    navigationLink: {
      link: "https://minio.linkplus.dev:9000/ffk/PDFs/Rregullorja%20e%20Garave%20te%20Liges%20se%20Vjazave%20dhe%20Kupes,%20Edicioni%202024%20-%202025.pdf",
    },
  },
  {
    title: "Propozicionet e Garave për futsall 2024/25",
    navigationLink: {
      link: "",
    },
  },
  {
    title: "Rregullorja për statusin e lojtarëve",
    navigationLink: {
      link: "https://minio.linkplus.dev:9000/ffk/PDFs/Rregullore%20mbi%20Regjistrimin,%20Statusin%20dhe%20Transferin%20e%20Lojtareve%20BESA.pdf",
    },
  },
  {
    title: "Rregullorja e UEFA-s për infrastrukturën e stadiumeve",
    navigationLink: {
      link: "",
    },
  },
  {
    title: "Politika mbi mbrojtjen dhe sigurinë e fëmijëve në futboll",
    navigationLink: {
      link: "https://minio.linkplus.dev:9000/ffk/PDFs/POLITIKA%20MBI%20MBROJTJEN%20DHE%20SIGURINE%20E%20FEMIJEVE%20NE%20FUTBOLL.pdf",
    },
  },
];

const NormativeActs = () => {
  return (
      <div className={styles.MainWrapper}>
        <div className={styles.PrimaryTitleWrapper}>
          <h1>Aktet Normative</h1>
        </div>
        <div className={styles.ListWrapper}>
          {NormativeActs_data.map((data, index) => (
              <CardRow
                  key={index}
                  data={{
                    ...data,
                    id: index + 1,
                    navigationLink: {
                      ...data.navigationLink,
                      link: "Lexo Dokumentin",
                      onLinkClick: () =>
                          window.open(data.navigationLink?.link, "_blank"),
                    },
                  }}
                  colors="INVERTED"
              />
          ))}
        </div>
      </div>
  );

};

export default NormativeActs;
