import React, {useEffect, useState} from 'react';
import {Button, Modal, Tooltip} from 'antd';
import styles from "./Raporto.module.scss";
import Phone from "../../assets/images/phone.png";
import {PhoneFilled}  from  '@ant-design/icons'
const Raporto = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  useEffect(() => {
    const hasClosedModal = localStorage.getItem("modalClosed");

    if (!hasClosedModal) {
      setIsModalVisible(false);
    }
  }, []);

  const handleClose = () => {
    setIsModalVisible(false);
    localStorage.setItem("modalClosed", "true");
  };

  return (
      <>
        <Tooltip title="Raporto parregullsitë" placement="left">
        <Button type="primary" onClick={showModal}>
          <PhoneFilled />
        </Button>
        </Tooltip>
        <Modal
            title="Raporto!"
            className={styles.RaportoModal}
            open={isModalVisible}
            onCancel={handleClose}
            footer={null}
        >
          <h2>Raporto çdo parregullësi apo kurdisje të ndeshjeve në</h2>
          <div className={styles.RaportoModalContent}>
            <img src={Phone} />
            <h1>0800 16 999</h1>
          </div>
          <h2>Thirrja juaj është pa pagesë dhe mbetet konfidenciale.</h2>
        </Modal>
      </>
  );
};

export default Raporto;