import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./SearchBar.module.scss";

const SearchBar = ({ closeModal }: { closeModal: () => void }) => {
    const [query, setQuery] = useState("");
    const navigate = useNavigate();

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setQuery(e.target.value);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter" && query.length >= 3) {
            navigate(`/search?s=${encodeURIComponent(query)}`);
            setQuery("");
            closeModal();
        }
    };

    return (
        <div>
            <input
                className={styles.SearchBarInput}
                type="text"
                placeholder="Search..."
                value={query}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
            />
        </div>
    );
};

export default SearchBar;
