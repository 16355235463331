import React from "react";
import styles from "./FFK-shop.module.scss";

const FFKShop: React.FC = () => {
  return (
      <div className={styles.MainWrapper}>
        <div className={styles.FFKShopContainer}>
          <h1>A jeni gati?!  Dyqani ynë po vjen së shpejti! ⚽</h1>
        </div>
      </div>
  );
};

export default FFKShop;
