import React, { useEffect, useState } from "react";
import { Form, Input, Select, Button, message } from "antd";
import { useTranslation } from "react-i18next";
import { FormValues } from "../../../types/types";
import usePost from "../../../hooks/usePost";
import styles from "./VolunteerForm.module.scss";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import Cookies from "js-cookie";

const { Option } = Select;

interface VolunteerFormProps {
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
}

const VolunteerForm: React.FC<VolunteerFormProps> = ({ setCurrentStep }) => {
  const { t } = useTranslation();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [formValues, setFormValues] = useState<FormValues>({
    fullName: "",
    city: "",
    gender: "",
    personalNumber: "",
    email: "",
    age: "",
  });
  const [form] = Form.useForm();

  const { post, loading, error } = usePost("/volunteers/apply");

  const cities = [
    "Artanë",
    "Dardanë",
    "Deçan",
    "Sharr",
    "Drenas",
    "Ferizaj",
    "Fushë Kosovë",
    "Gjakovë",
    "Gjilan",
    "Graçanicë",
    "Hani Elezit",
    "Burim",
    "Junik",
    "Kaçanik",
    "Klinë",
    "Kllokot",
    "Albanik",
    "Lipjan",
    "Malishevë",
    "Mamushë",
    "Mitrovicë",
    "Mitrovicë e Veriut",
    "Kastriot",
    "Partesh",
    "Pejë",
    "Besianë",
    "Prishtinë",
    "Prizren",
    "Rahovec",
    "Shtërpcë",
    "Shtime",
    "Skënderaj",
    "Therandë",
    "Viti",
    "Vushtrri",
    "Zubin Potok",
    "Zveçan",
  ];

  useEffect(() => {
    const cookieConsent = Cookies.get("cookie_consent");

    if (cookieConsent === "accepted") {
      const savedForm = Cookies.get("volunteer_form");
      if (savedForm) {
        form.setFieldsValue(JSON.parse(savedForm));
      }
    }
  }, [form]);

  const onFinish = async (values: FormValues) => {
    if (!executeRecaptcha) {
      message.error("reCAPTCHA not yet loaded");
      return;
    }

    try {
      const token = await executeRecaptcha("submit");
      const payload = {
        ...values,
        age: Number(values.age),
        recaptchaToken: token,
      };

      // const payload = {
      //   ...values,
      //   age: Number(values.age),
      //   recaptchaToken: token,
      // };

      const cookieConsent = Cookies.get("cookie_consent");
      if (cookieConsent === "accepted") {
        Cookies.set("volunteer_form", JSON.stringify(values), { expires: 7 });
      }

      await post(payload);
      message.success(
        t(
          "app.volunteers.applicationSubmitted",
          "Application submitted successfully!",
        ),
      );
      setCurrentStep(2);
    } catch (err) {
      console.error("Form submission error:", error);
      message.error(
        t(
          "app.volunteers.applicationError",
          "There was an error submitting your application.",
        ),
      );
    }
  };

  return (
    <div className={styles.VolunteerFormContainer}>
      <h1>Programi i Vullnetareve</h1>
      <p>
        Formulari i aplikimit do të përmbajë një datë fillimi dhe përfundimi të
        periudhës së aplikimit, si dhe një përshkrim të shkurtër të ngjarjes për
        të cilën po aplikoni.
      </p>
      <Form onFinish={onFinish} initialValues={formValues}>
        <div className={styles.VolunteerForm}>
          <div className={styles.VolunteerFormAlignment}>
            <div>
              <p>{t("app.volunteers.name&surname", "Emri & Mbiemri")}</p>
              <Form.Item
                name="fullName"
                rules={[
                  {
                    required: true,
                    message: t("app.validations.required", "Required"),
                  },
                  {
                    pattern: /^[A-Za-z]+ [A-Za-z]+$/,
                    message: t(
                      "app.volunteers.validation.fullName",
                      "Duhet të jetë vetëm me shkronja dhe të përmbajë dy fjalë, p.sh. Filan Fisteku",
                    ),
                  },
                ]}
              >
                <Input
                  value={formValues.fullName}
                  onChange={(e) =>
                    setFormValues({ ...formValues, fullName: e.target.value })
                  }
                />
              </Form.Item>
            </div>
            <div>
              <p>{t("app.volunteers.city", "Qyteti")}</p>
              <Form.Item
                name="city"
                rules={[
                  {
                    required: true,
                    message: t("app.validations.required", "Required"),
                  },
                  {
                    pattern: /^[A-Za-zçÇëË\s]+$/,
                    message: t(
                      "app.volunteers.validation.cityFormat",
                      "Qyteti duhet të përmbajë vetëm shkronja",
                    ),
                  },
                ]}
              >
                <Select
                  showSearch
                  value={formValues.city}
                  onChange={(value) =>
                    setFormValues({ ...formValues, city: value })
                  }
                  placeholder="Select a city"
                >
                  {cities.map((city) => (
                    <Option key={city} value={city}>
                      {city}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </div>

          <div className={styles.VolunteerFormAlignment}>
            <div>
              <p>{t("app.volunteers.gender", "Gjinia")}</p>
              <Form.Item
                name="gender"
                rules={[
                  {
                    required: true,
                    message: t("app.validations.required", "Required"),
                  },
                ]}
              >
                <Select
                  value={formValues.gender}
                  onChange={(value) =>
                    setFormValues({ ...formValues, gender: value })
                  }
                >
                  <Option value="FEMALE">Femër</Option>
                  <Option value="MALE">Mashkull</Option>
                </Select>
              </Form.Item>
            </div>
            <div>
              <p>{t("app.volunteers.idNumber", "Numri personal")}</p>
              <Form.Item
                name="personalNumber"
                rules={[
                  {
                    required: true,
                    message: t("app.validations.required", "Required"),
                  },
                  {
                    pattern: /^\d{10}$/,
                    message: t(
                      "app.volunteers.validation.personalNumber",
                      "Numri personal duhet të përmbajë 10 numra",
                    ),
                  },
                ]}
              >
                <Input
                  value={formValues.personalNumber}
                  onChange={(e) =>
                    setFormValues({
                      ...formValues,
                      personalNumber: e.target.value,
                    })
                  }
                />
              </Form.Item>
            </div>
          </div>

          <div className={styles.VolunteerFormAlignment}>
            <div>
              <p>{t("app.volunteers.email", "Email")}</p>
              <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: t("app.validations.required", "Required"),
                    },
                    {
                      pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.(com|net|edu|)$/i,
                      message: t(
                          "app.volunteers.validation.emailFormat",
                          "Formati i pavlefshëm i email-it.",
                      ),
                    },
                  ]}
              >
                <Input
                    type="email"
                    value={formValues.email}
                    onChange={(e) =>
                        setFormValues({ ...formValues, email: e.target.value.trim() })
                    }
                />
              </Form.Item>

            </div>
            <div>
              <p>{t("app.volunteers.age", "Age")}</p>
              <Form.Item
                  name="age"
                  rules={[
                    {
                      required: true,
                      message: t("app.validations.required", "Required"),
                    },
                    {
                      validator: (_, value) => {
                        if (!/^\d+$/.test(value)) {
                          return Promise.reject(
                              new Error(
                                  t("app.volunteers.validation.ageInvalid", "Please enter a valid age")
                              )
                          );
                        }
                        if (/^0\d+/.test(value)) {
                          return Promise.reject(
                              new Error(
                                  t("app.volunteers.validation.noLeadingZeros", "Age cannot start with 0")
                              )
                          );
                        }
                        const numericAge = Number(value);
                        if (numericAge < 16) {
                          return Promise.reject(
                              new Error(
                                  t("app.volunteers.validation.ageGreaterThan16", "Age must be 16 or greater")
                              )
                          );
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
              >
                <Input
                    type="text"
                    value={formValues.age}
                    onChange={(e) => {
                      let value = e.target.value.replace(/\D/g, "");
                      setFormValues({ ...formValues, age: value });
                    }}
                />
              </Form.Item>

            </div>
          </div>
        </div>
        <div className={styles.ButtonAlignment}>
          <Button
            type="primary"
            htmlType="submit"
            className={styles.ButtonStyle}
          >
            Apliko tani
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default VolunteerForm;
