import React from 'react';
import styles from './TermsOfUse.module.scss';

const PrivacyPolicy = () => {
  return (
      <div className={styles.MainWrapper}>
        <div className={styles.TermsContainer}>
          <h1>FFK - Privacy Policy</h1>
          <div className={styles.ListWrapper}>
            <div>
              <h2>Introduction</h2>
              <p>
                Welcome to FFK! This Privacy Policy is designed to help you understand how we collect, use,
                disclose, and safeguard your personal information. By using our website, you agree to the terms outlined in
                this policy.
              </p>
            </div>

            <div className={styles.UseOfTheApp}>
            <div>
              <h2>Information We Collect</h2>
              <p>We collect the following information:</p>
              <ul>
                <li><p><span>Personal Data: </span>We collect your personal data when you apply for Volunteer's Program. </p>
                </li>
              </ul>
            </div>
              <br/>
            <div>
              <h2>How We Use Your Information</h2>
              <p>We use the collected information for the following purposes:</p>
              <ul>
                <li><p><span>Personalization: </span>To personalize your experience and provide content tailored to
                  your preferences. </p>
                </li>
                <li><p><span>Account Management: </span> To manage your account, including enabling you to save and
                  access your favorite news.</p>
                </li>
                <li><p><span>Communication:</span> To send you updates, notifications, and other information related to
                  the website.</p>
                </li>
              </ul>
            </div>
            </div>

            <div>
              <h2>Data Security</h2>
              <p>We prioritize the security of your information and take appropriate measures to protect it.</p>
            </div>

            <div>
              <h2>Disclosure of Information</h2>
              <p>We do not sell, trade, or otherwise transfer your personally identifiable information to third parties.
                However, we may share aggregated, non-personal information with third parties for statistical
                purposes.</p>
            </div>

            <div>
              <h2>Your Choices</h2>
              <p>
                If you have any concerns or requests regarding your data, please contact us
                at <span>info@ffk-kosova.com</span>.
              </p>
            </div>

            <div>
              <h2>Children's Privacy</h2>
              <p>
                Our website is not intended for children under the age of 12. We do not knowingly collect or solicit
                personal information from children. If you are a parent or guardian and believe that your child has provided us
                with personal information, please contact us.
              </p>
            </div>

            <div>
              <h2>Changes to This Privacy Policy</h2>
              <p>We reserve the right to update our Privacy Policy at any time. You will be notified of any changes by
                posting the new Privacy Policy on this page.</p>
            </div>

            <div>
              <h2>Contact Us</h2>
              <p>
                If you have any questions or concerns about this Privacy Policy, please contact us at <span>info@ffk-kosova.com</span>.
              </p>
            </div>

            <div>
              <p>Last updated: 25.04.2025</p>
            </div>
          </div>
        </div>
      </div>
  )
      ;
};

export default PrivacyPolicy;
