import React, {useMemo, useEffect, useState, useCallback} from "react";
import styles from "./Home.module.scss";
import { useTranslation } from "react-i18next";
import useFetch from "../../hooks/useFetch";
import {CategoriesResponse, NationalGamesResponse} from "../../types/types";
import HeroSection from "../../components/hero-section/HeroSection";
import GameCard from "../../components/game-card/GameCard";
import MainNews from "../../components/main-news/MainNews";
import CompetitionGameCard from "../../components/competition-game-card/CompetitionGameCard";
import Gallery from "../../components/gallery/Gallery";
import VideoCard from "../../components/video-card/VideoCard";
import Partners from "../../components/partners/Partners";
import RightArrow from "../../assets/images/arrow-right.png";
import TeamsActivity from "./teams-activity/TeamsActivity";
import Spinner from "../../components/_shared/loader/Spinner";
import {useNavigate} from "react-router-dom";
import {replaceStringSpaces} from "../../utils/utils";

const Home = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [videos, setVideos] = useState<any>([]);
  const isMobile = window.innerWidth >= 768;

  const { data: nationalGames, loading: nationalGamesLoading } = useFetch<NationalGamesResponse>(
      "/national-games/next-three-games"
  );

  const { data } = useFetch<CategoriesResponse>("/categories/top-level");

  const { data: latestVideos, loading: videosLoading } = useFetch<any[]>("/videos/latest");

  const albiMallSuperliga = data?.find(category => category.name === "GARAT")
      ?.subCategories?.find(sub => sub.name === "GARAT")
      ?.subCategories?.find(sub => sub.name === "Albi Mall Superliga");

  const referenceLeagueId = albiMallSuperliga?.referenceLeagueId;

  const { data: completedWeeks, loading: weeksLoading } = useFetch<number[]>(
      `/matches/${referenceLeagueId}/completed-gameweeks`
  );

  const [matches, setMatches] = useState<any[]>([]);

  const handleNavigation = useCallback((x: any) => {
    if (!x || !x.name || !x.referenceLeagueId) return;

    navigate(`/garat/${replaceStringSpaces(x.name)}`, {
      state: { categoryId: x.referenceLeagueId },
    });
  }, [navigate]);

  const sortedNationalGames = useMemo(() => {
    const order = { UPCOMING: 1, LIVE: 2, FINISHED: 3 };
    return [...(nationalGames ?? [])].sort(
        (a, b) => order[a.status] - order[b.status]
    );
  }, [nationalGames]);

  useEffect(() => {
    if (completedWeeks && completedWeeks.length > 0) {
      const lastCompletedWeek = Math.max(...completedWeeks);
      fetch(process.env.REACT_APP_API_URL + `/matches/${referenceLeagueId}/week/${lastCompletedWeek}`)
          .then((response) => response.json())
          .then((data) => setMatches(data))
          .catch((error) => console.error("Error fetching matches:", error));
    }
  }, [completedWeeks]);

  useEffect(() => {
    if (latestVideos) {
      setVideos(latestVideos.map(video => video.youtubeUrl));
    }
  }, [latestVideos]);

  if (nationalGamesLoading || weeksLoading) {
    return <Spinner />;
  }

  return (
      <>
        <div className={styles.HomeContainer}>
          <HeroSection />
          {/*<Raporto />*/}
          {sortedNationalGames.length > 0 && (
              <div className={styles.NationalGames}>
                <h1>{t("app.home.nationalGames", "Ndeshjet kombëtare")}</h1>
                <div className={styles.GameCardContainer}>
                  {sortedNationalGames?.map((game) => (
                      <GameCard key={game.id} {...game} />
                  ))}
                </div>
              </div>
          )}

          {/* Main news Section */}
          <div>
            <MainNews />
          </div>

          {/* Men's Activity */}
          <TeamsActivity team="MAN" />

          {/* Competition Games */}
          <div className={styles.NationalGames}>
            <h1 onClick={() => handleNavigation(albiMallSuperliga)}>
              {albiMallSuperliga?.name}
            </h1>
            <div className={styles.CompetitionGameCard}>
              {matches?.map((match: any) => (
                  <CompetitionGameCard
                      key={match.id}
                      date={match.date}
                      homeTeamName={match.homeTeamName}
                      homeTeamLogo={match.homeTeamLogo}
                      awayTeamName={match.awayTeamName}
                      awayTeamLogo={match.awayTeamLogo}
                      homeTeamScore={match.homeTeamScore}
                      awayTeamScore={match.awayTeamScore}
                      status={match.status}
                  />
              ))}
            </div>
          </div>

          {/*/!* Women's Activity *!/*/}
          <TeamsActivity team="WOMAN" />

          {/* Photo Gallery */}
          <div>
            <Gallery />
          </div>

          {/* Video Section */}
          {isMobile ? (
              <div className={styles.VideoSection}>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <h1>{t("app.home.watchOurVideos", "Shikoni Videot Tona")}</h1>
                  <div className={styles.VisitChannel}>
                    <a
                        href="https://www.youtube.com/c/FederataeFutbolliteKosov%C3%ABs"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                      {t("app.home.visitOurChannel", "Për më shumë vizitoni kanalin tonë")}
                    </a>
                    <img src={RightArrow} alt={"Right Arrow"} />
                  </div>
                </div>
                <div className={styles.VideoCardContainer}>
                  {videos.length > 0 ? (
                      videos.map((videoUrl: any, index: any) => <VideoCard key={index} videoUrl={videoUrl} />)
                  ) : (
                      <p>No videos available</p>
                  )}
                </div>
              </div>
          ) : (
              <div className={styles.VideoSection}>
                <div>
                  <h1>{t("app.home.watchOurVideos", "Shikoni Videot Tona")}</h1>
                </div>
                <div className={styles.VideoCardContainer}>
                  {videos.length > 0 ? (
                      videos.map((videoUrl: any, index: any) => <VideoCard key={index} videoUrl={videoUrl}/>)
                  ) : (
                      <p>No videos available</p>
                  )}
                </div>
                <div className={styles.VisitChannel}>
                  <a href="https://www.youtube.com/c/FederataeFutbolliteKosov%C3%ABs" target="_blank" rel="noopener noreferrer">
                  {t("app.home.visitOurChannel", "Për më shumë vizitoni kanalin tonë")}
                </a>
                <img src={RightArrow} alt={"Right Arrow"}/>
              </div>
            </div>
            )}

          {/* Partners Section */}
          <div className={styles.PartnerSection}>
          <h1>{t("app.home.Partners", "Partnerët tanë")}</h1>
            <Partners />
          </div>
        </div>
      </>
  );
};

export default Home;
