import { useEffect, useState } from "react";
import { SearchResponse, Pageable } from "../types/types";

const useFetchSearch = (query: string, language: string, pageable: Pageable) => {
  const [data, setData] = useState<any | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    if (query.length < 3) {
      setData(null);
      setLoading(false);
      return;
    }

    const delayDebounce = setTimeout(() => {
      const fetchData = async () => {
        setLoading(true);
        setError(null); // Reset error before fetching

        try {
          const params = new URLSearchParams({
            query,
            language,
            pageable: JSON.stringify(pageable),
          }).toString();

          const response = await fetch(
              `${process.env.REACT_APP_API_URL}/posts/search?${params}`
          );

          if (!response.ok) {
            throw new Error(`Error: ${response.status}`);
          }

          const result: SearchResponse = await response.json();
          setData(result);
        } catch (err) {
          setError(err as Error);
        } finally {
          setLoading(false);
        }
      };

      fetchData();
    }, 300);

    return () => clearTimeout(delayDebounce);
  }, [query, language, pageable]);

  return { data, loading, error };
};

export default useFetchSearch;
