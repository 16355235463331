import React, {useState, useMemo} from "react";
import {Badge, Button} from "antd";
import useFetch from "../../hooks/useFetch";
import ArticleArrow from "../../assets/images/article-arrow.png";
import ScrollDown from "../../assets/images/scroll-indicator.png";
import ScrollUp from "../../assets/images/scroll-up.png";
import styles from "./MainNews.module.scss";
import {useTranslation} from "react-i18next";
import {replaceStringSpaces} from "../../utils/utils";
import {useNavigate} from "react-router-dom";
import dayjs from "dayjs";
import DOMPurify from "dompurify";

interface Post {
  id: string;
  alTitle: string;
  enTitle: string;
  alDescription: string;
  enDescription: string;
  mainImageUrl: string;
  publishedDate: string;
}

interface PaginatedResponse<T> {
  content: T[];
  totalElements: number;
  totalPages: number;
  number: number;
  size: number;
}

const MainNews: React.FC = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const {
    data: response,
    loading,
    error,
  } = useFetch<PaginatedResponse<Post>>(
      "/posts?subCategoryId=aadb1bc8-a1b6-4ed6-b48e-aecc60a0558f&childSubcategoryId=f81c4fea-504a-4ef3-8871-c9c89a757c3a",
  );
  const {i18n} = useTranslation();
  const navigate = useNavigate();

  const posts = response?.content || [];
  const currentPost = posts[currentIndex] || {
    alTitle: "",
    enTitle: "",
    alDescription: "",
    enDescription: "",
    mainImageUrl: "",
    publishedDate: "",
  };

  const navigationTitle = useMemo(() => {
    return i18n.language === "al"
        ? replaceStringSpaces(currentPost.alTitle)
        : replaceStringSpaces(currentPost.enTitle);
  }, [i18n.language, currentPost.alTitle, currentPost.enTitle]);

  const truncateText = (text: string, maxLength: number) =>
      text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;

  const title =
      i18n.language === "al" ? currentPost.alTitle : currentPost.enTitle;
  const description =
      i18n.language === "al"
          ? truncateText(currentPost.alDescription, 800)
          : truncateText(currentPost.enDescription, 800);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
        prevIndex === 0 ? posts.length - 1 : prevIndex - 1,
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
        prevIndex === posts.length - 1 ? 0 : prevIndex + 1,
    );
  };

  if (loading) {
    return <div></div>;
  }

  if (error || posts.length === 0) {
    return <div>Error loading posts</div>;
  }

  const formatDate = (dateString: string) => {
    return dayjs(dateString).format('MMM D, YYYY');
  };

  return (
      <div className={styles.MainNewsContainer}
      >
        <img
            onClick={() => navigate(`/lajmet/${navigationTitle}`, {state: currentPost})}
            style={{cursor: 'pointer'}}
            src={currentPost.mainImageUrl}
            alt="Main News"
            className={styles.MainImage}
        />
        <div onClick={() => navigate(`/lajmet/${navigationTitle}`, {state: currentPost})}>
          <div className={styles.Badge}>
            <Badge count="LAJMET"/>
            <span>
              {formatDate(currentPost.publishedDate)}
          </span>
          </div>
          <h1 style={{cursor: 'pointer'}}>{title}</h1>
          <div
              className={styles.Description}
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(description),
              }}
          />

          <Button
              onClick={() =>
                  navigate(`/lajmet/${navigationTitle}`, {state: currentPost})
              }
          >
            Read Full Article
            <img src={ArticleArrow} alt="Arrow"/>
          </Button>
        </div>
        <div className={styles.ScrollButtons}>
          <img src={ScrollUp} alt="Scroll Up" onClick={handlePrev}/>
          <img src={ScrollDown} alt="Scroll Down" onClick={handleNext}/>
        </div>
      </div>
  );
};

export default MainNews;
